import WertWidget from '@wert-io/widget-initializer';
// import { v4 as uuidv4 } from 'uuid';
import {
    Buffer
} from 'buffer/';
import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";

const state = {
    itemsOnCart: [],
    itemsPrice: [],
    totalItems: 0,
    totalPrice: 0,
    checkoutStatus: false,
    cartIsOpen: false,
    landType: "",
    landCollection: "",
    isWertDialogShow: false,
    isWertCartNoticeDialogShow: false,
    landPlotBuyResult: {
        error: false,
        msg: ""
    },
    wertLoaded: false,
    landPlotBlcProcess: {
        title: "",
        msg: ""
    },
    showDialogPaymentStripe: false,
    addCartException: {
        title: "",
        msg: ""
    },
}


const actions = {


    // Action assign authenticate user
    hideDialogPaymentStripe(state) {
        state.showDialogPaymentStripe = false;
    },

    addItems({
        commit,
        state
    }, {
        items
    }) {

        const LIMIT_ADD_CART = 300;

        let prepair = [];

        items.forEach(e => {
            var duplicated = state.itemsOnCart.find(function (i) {
                if (_.isEqual(e.geometry.bbox, i.geometry.bbox)) {
                    return true;
                }
                return false;
            });

            if (!duplicated) {
                prepair.push(e);
            }
        })

        if (prepair.length + state.itemsOnCart.length > LIMIT_ADD_CART) {
            // Show error 
            commit("AddCartException", {
                error: true,
                title: "",
                msg: "You can only add a maximum of 300 land plots into your cart at once. Please checkout with your current cart and you can continue shopping after."
            });
            return
        }
        commit("addItemMutation", {
            items: prepair
        });
        commit("openCart", {
            status: true
        });
    },

    removeItem({
        commit
    }, {
        index
    }) {

        commit("removeItemMutation", {
            index
        });
    },

    removeItemAll({
        commit
    }) {
        commit("removeItemMutationAll");
    },



    checkout({
        commit
    }, {
        type,
        collection_id
    }) {
        commit("onCheckout", {
            type,
            collection_id
        });
    },

    closeCheckoutDialog({
        commit
    }) {
        commit("closeCheckoutDialog");
    },

    openCart({
        commit
    }, {
        status
    }) {
        commit("openCart", {
            status
        });
    },

    updatePrice({
        commit
    }, {
        price,
        price_items
    }) {
        commit("addTotalPrice", {
            price,
            price_items
        });
    },

    openPaymentMethod({
        commit
    }) {
        commit("openPaymentMethod");
    },

    cleanItemsAll({
        commit
    }) {

        commit("cleanItemsAllMutation");
    },







    /* Payment with wert ..etc */
    showWertNotice({
        commit
    }) {
        commit("UICartWertNoticeStatus", true);
        commit("closeCheckoutDialog");
    },

    hideWertNotice({
        commit
    }) {
        commit("UICartWertNoticeStatus", false);
    },



    /**
     * Payment with land credit
     */
    creditPayment({
        commit,
        dispatch,
        state
    }) {
        commit("closeCheckoutDialog");
        axios.post(`/api/land/payment/credit`, {
            price: state.totalPrice,
            type: state.landType,
            collection: state.landCollection,
            items: state.itemsOnCart.map(e => {
                return {
                    location: e.geometry.coordinates,
                    bbox: e.geometry.bbox,
                    areas: e.areas
                };
            }),
        }).then(response => {
            if (response.data && !response.data.error) {
                // Transaction completed, refresh map event
                dispatch("account/getCredits", {}, {
                    root: true
                });


                commit("cleanItemsAllMutation");
                commit("SetLandPlotBuyResult", {
                    error: false,
                    title: "Land plots purchased successfully.",
                    msg: ""
                })
            } else {
                commit("SetLandPlotBuyResult", {
                    error: true,
                    title: "Unable to complete transaction",
                    msg: response.data.msg
                })
            }
        }).catch(() => {
            commit("SetLandPlotBuyResult", {
                error: false,
                title: "Unable to complete transaction",
                msg: "Please try again, network error"
            })
        });
    },

    async creditPaymentStripe({
        state
    }) {
        try {
            const res = await axios
                .post(`/api/stripe/buy`, {
                    price: state.totalPrice,
                    type: state.landType,
                    collection: state.landCollection,
                    items: state.itemsOnCart.map(e => {
                        return {
                            location: e.geometry.coordinates,
                            bbox: e.geometry.bbox,
                            areas: e.areas
                        };
                    })
                })
            return res.data
        } catch (error) {
            // Handle errors
            return {
                error: true,
                msg: "Please try again, network error"
            }
        }
    },

    wertioPayment({
        commit,
        state
    }) {
        // Request to backend ready for payment data with wert dot io 
        commit("UICartWertNoticeStatus", false);
        axios
            .post('/api/land/payment/wertio', {
                type: state.landType,
                collection: state.landCollection,
                items: state.itemsOnCart.map(e => {
                    return {
                        location: e.geometry.coordinates,
                        bbox: e.geometry.bbox,
                        areas: e.areas
                    };
                }),
            }).then(response => {
                let config = response.data;
                let transactionID = config.tid;
                let amount = state.itemsOnCart.length
                commit("UIWertDialogShow", {
                    display: true
                });
                try {
                    let signedData = response.data.signedData;
                    const otherWidgetOptions = {
                        partner_id: config.partnerID, //'01FFHQR89W38Y98278392090',
                        commodity: config.commodity, // 'ETH:Ethereum-Goerli',
                        container_id: 'widget_landplot',
                        click_id: transactionID, // unique id of purhase in your system
                        origin: config.origin, // 'https://sandbox.wert.io', // this option needed only for this example to work
                        height: 500,
                        listeners: {
                            'loaded': () => {
                                console.log('loaded')
                                commit("UITopupWertLoadingStatus", true);
                            },
                            'error': (e) => {
                                console.log(e, "Errorrr")
                                axios.patch('/api/credit/payment_status', {
                                    tid: transactionID,
                                    status: 4,
                                    payload: e
                                })
                                commit("showPaymentStripe");

                            },
                            'payment-status': (s) => {
                                console.log(s);
                                switch (s.status) {
                                    case 'progress':
                                        axios.patch('/api/credit/payment_status', {
                                            tid: transactionID,
                                            status: 1,
                                            payload: s
                                        })
                                        break;
                                    case 'failed':
                                        commit("showPaymentStripe");
                                        break
                                    case 'success':
                                        axios.patch('/api/credit/payment_status', {
                                            tid: transactionID,
                                            status: 2,
                                            payload: s
                                        })
                                        setTimeout(() => {
                                            commit("UIWertDialogShow", {
                                                display: false
                                            });
                                            commit("cleanItemsAllMutation");
                                            commit("SetLandPlotBuyResult", {
                                                error: false,
                                                title: "Land plots purchased successfully.",
                                                msg: ""
                                            })
                                        }, 3000);

                                        break;
                                    default:
                                        break;
                                }
                            },
                            'position': (p) => {
                                console.log(p);
                            }
                        },
                    };

                    const nftOptions = {
                        extra: {
                            item_info: {
                                author: "Gate of Abyss",
                                name: `Buy ${amount} Land Plot`,
                                seller: "Gate of Abyss",
                            }
                        },
                    };


                    const wertWidget = new WertWidget({
                        ...signedData,
                        ...otherWidgetOptions,
                        ...nftOptions
                    });

                    setTimeout(() => {
                        wertWidget.mount();
                    }, 1000);
                } catch (error) {
                    // Can not connect to wertio 
                    console.log('e', error);
                    commit("UIWertDialogShow", {
                        display: false
                    });
                }
            }).catch(e => {
                commit("UIWertDialogShow", {
                    display: false
                });
                commit("SetLandPlotBuyResult", {
                    error: true,
                    msg: e.response.data.msg
                })
            })
    },


    terminateCartWertIOPayment({
        commit
    }) {
        commit("UIWertDialogShow", {
            display: false
        });
    },





    /**
     * VueX action for crypto Payment
     */
    async ethCryptoPayment({
        commit,
        dispatch
    }) {

        commit("SetLandPlotBlcProcess", {
            msg: "Payment transaction started",
        })
        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/land/payment/eth_crypto', {
                type: state.landType,
                collection: state.landCollection,
                items: state.itemsOnCart.map(e => {
                    return {
                        location: e.geometry.coordinates,
                        bbox: e.geometry.bbox,
                        areas: e.areas
                    };
                }),
            });
            if (response.data.error) {
                return commit("SetLandPlotBuyResult", {
                    error: true,
                    msg: response.data.msg
                })

            }
            paymentTransactionData = response.data;
        } catch (error) {
            return commit("SetLandPlotBuyResult", {
                error: true,
                msg: error
            })

        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;


        //this.$swal("Please login to access", "Require login", "error");
        if (typeof window.ethereum === "undefined") {
            commit("SetLandPlotBuyResult", {
                error: true,
                msg: "Please install Metamask and connect your wallet"
            });

            await axios.post('/api/land/payment/eth_crypto/status', {
                tid: transactionID,
                step: 11,
                payload: {},
                result: false,
                content: "Please install Metamask and connect your wallet"
            });
            return
        }

        // Request connect eth network
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: targetNetworkId
                }],
            });
            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            // const instance = new Web3(window.ethereum);
            // // Get necessary info on your node
            // var networkId = await instance.eth.net.getId();
            // var coinbase = await instance.eth.getCoinbase();
            // var balance = await instance.eth.getBalance(coinbase);
            // this.networkId = networkId;
            // this.address = coinbase;
            // this.balance = balance;
            // console.log("initWeb3 networkId: ", networkId);
            // console.log("coinbase networkId: ", coinbase);
            // console.log("balance networkId: ", balance);
        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);
            await axios.post('/api/land/payment/eth_crypto/status', {
                tid: transactionID,
                step: 12,
                payload: {},
                result: false,
                content: "User denied web3 access. Please install Metamask and connect your wallet"
            });
            return commit("SetLandPlotBuyResult", {
                error: true,
                msg: "User denied web3 access. Please install Metamask and connect your wallet"
            })
        }


        var isWorking = true;

        window.addEventListener("beforeunload", function (e) {

            if (transactionID) {
                axios.post('/api/land/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 12,
                    payload: {},
                    result: false,
                    content: "User close browser"
                }).then(() => {}).catch(() => {});
            }

            if (isWorking == false) return false;
            var confirmationMessage = "aaaaa";
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage;
        });

        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];

                commit("SetLandPlotBlcProcess", {
                    msg: "IMPORTANT! Please do not close this.",
                    title: 'Transaction Processing ...'
                })

                await axios.post('/api/land/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 20,
                    payload: {
                        account: account
                    },
                    result: true,
                    content: "Request open dialog Payment metamask!"
                });

                axios.patch('/api/credit/payment_status', {
                    tid: transactionID,
                    status: 1,
                    payload: {}
                })





                let result = await web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                    });



                if (result) {
                    console.log('ETH Transfer result', result);
                    return result;
                } else {
                    await axios.post('/api/land/payment/eth_crypto/status', {
                        tid: transactionID,
                        step: 21,
                        payload: {},
                        result: false,
                        content: "Send transaction error."
                    });
                    commit("SetLandPlotBuyResult", {
                        error: true,
                        msg: "Transaction error."
                    })
                }

            } catch (error) {

                await axios.post('/api/land/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 22,
                    payload: {
                        error: error.message
                    },
                    result: false,
                    content: "Transaction error." + error.message
                });
                commit("SetLandPlotBuyResult", {
                    error: true,
                    msg: "Transaction error." + error.message
                })
            }
            return
        }
        // After send eth
        let result = await sendETH(amountCurrency)
        isWorking = false;
        if (result) {

            await axios.post('/api/land/payment/eth_crypto/status', {
                tid: transactionID,
                step: 30,
                payload: result,
                result: false,
                content: "ETH Transfer success result, Validating your transaction ...."
            });

            commit("SetLandPlotBlcProcess", {
                msg: "Validating your transaction ....",
                title: 'Blockchain transfer success. Please wait.'
            })

            await axios.patch('/api/credit/payment_status', {
                tid: transactionID,
                status: 2,
                payload: result
            })

            axios.post('/api/land/payment/eth_crypto/verify', {
                tid: transactionID,
                txh: result.transactionHash
            }).then(() => {
                dispatch('account/getCredits', {}, {
                    root: true
                });
                commit("cleanItemsAllMutation");
                commit("SetLandPlotBuyResult", {
                    error: false,
                    title: "Land plots purchased successfully.",
                    msg: "Congratulations! Your transaction has been completed and confirmed by the blockchain."
                })
                commit("closeCheckoutDialog");

                axios.post('/api/land/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 40,
                    payload: result,
                    result: true,
                    content: "Congratulations! Your transaction has been completed and confirmed by the blockchain."
                }).then(() => {}).catch(() => {});

            }).catch(() => {
                // Can not validate transaction ??? 
                commit("SetLandPlotBuyResult", {
                    error: true,
                    msg: "Can not validate transaction. Please contact us!",
                    title: 'Transaction address : ' + result.transactionHash
                })

                axios.post('/api/land/payment/eth_crypto/status', {
                    tid: transactionID,
                    step: 41,
                    payload: result,
                    result: false,
                    content: "Can not validate transaction. Please contact us!"
                }).then(() => {}).catch(() => {});
            })
        }
    },





};
const _ = require('lodash');
const mutations = {

    addItemMutation(state, {
        items
    }) {

        state.itemsOnCart.push(...items);
        /*
        items.forEach(e => {
            var duplicated = state.itemsOnCart.find(function (i) {
                if (_.isEqual(e.geometry.bbox, i.geometry.bbox)) {
                    return true;
                }
                return false;
            });

            if (!duplicated) {
                state.itemsOnCart.push(e);
            }
        })*/
        state.totalItems = state.itemsOnCart.length;
        // state.totalPrice = 25 * state.totalItems;
    },

    removeItemMutation(state, {
        index
    }) {
        state.itemsOnCart.splice(index, 1);
        state.totalItems = state.itemsOnCart.length;
        // state.totalPrice = 25 * state.totalItems;
    },

    removeItemMutationAll(state) {
        state.itemsOnCart = []
        state.totalItems = 0;
        // state.totalPrice = 25 * state.totalItems;
    },

    openPaymentMethod(state) {
        state.checkoutStatus = !state.checkoutStatus;
    },

    openCart(state, {
        status
    }) {
        state.cartIsOpen = status;
    },

    addTotalPrice(state, {
        price,
        price_items
    }) {
        state.totalPrice = price;
        state.itemsPrice = price_items;
    },

    onCheckout(state, {
        type,
        collection_id
    }) {
        state.landType = type;
        state.landCollection = type === 'collection' ? collection_id : '';
        state.checkoutStatus = true;
    },

    cleanItemsAllMutation(state) {
        state.itemsOnCart = [];
        state.totalItems = 0;
    },

    closeCheckoutDialog(state) {
        state.checkoutStatus = false
    },


    UICartWertNoticeStatus(state, status) {
        state.isWertCartNoticeDialogShow = status;
    },

    UIWertDialogShow(state, {
        display
    }) {
        state.isWertDialogShow = display
    },


    SetLandPlotBuyResult(state, {
        error,
        title,
        msg
    }) {
        state.landPlotBuyResult = {
            error,
            title,
            msg
        }
    },


    showPaymentStripe(state) {
        if (state.showDialogPaymentStripe) {
            state.showDialogPaymentStripe = false
        } else {
            state.showDialogPaymentStripe = true
        }
    },

    SetLandPlotBlcProcess(state, {
        error,
        title,
        msg
    }) {
        state.landPlotBlcProcess = {
            error,
            title,
            msg
        }
    },

    UITopupWertLoadingStatus(state, status) {
        state.wertLoaded = status;
    },

    AddCartException(state, {
        error,
        title,
        msg
    }) {
        state.addCartException = {
            error,
            title,
            msg
        }
    },

};

export const cart = {
    namespaced: true,
    state,
    actions,
    mutations
};