<style scoped>
.mapbox-row {
  position: relative;
}

.selected-item {
  position: absolute;
  top: 70px;
  right: 10px;
}

.selected-item .card {
  background: #1e293b;
  color: #fff;
}

#recommendations,
#information,
#analysis {
  background: #ffffff;
}

section {
  padding: 60px 0;
  overflow: hidden;
  color: #1e293b;
}

.section-title {
  font-weight: bold;
  text-transform: uppercase;
}

.section-title h2 {
  font-size: 32px;
}

.section-title h3 {
  font-size: 26px;
}

#recommendations .card {
  background-color: #f5f5f5;
  border: var(--tblr-card-border-width) solid #f5f5f5;
}

#footer {
  color: #444444;
  font-size: 14px;
  background: #18191c;
}

#navbar-menu {
  display: block !important;
}

section#information .table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

#payment-method .btn-close {
  filter: none;
}

#cart .text-truncate {
  max-width: 250px;
}

.vh-70 {
  --tblr-aspect-ratio: 70vh;
  height: 70vh;
}

#navbar-menu .navbar .text-muted {
  color: rgba(30, 41, 59, 0.7) !important;
}

div#apexchartsqdnn2s4ri {
  position: relative;
}

#apexchartsqdnn2s4ri > .notice-not-available {
  text-align: center;
  position: absolute;
  bottom: 40%;
  left: 40%;
}

.land-icon {
  width: 80px;
  margin-right: 1em;
}

/* Tooltip container */
.tooltip {
  margin-left: 5px;
  position: relative;
  opacity: 1;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 210px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.dropdown-item {
  display: block;
  text-align: center;
  background: white;
}

.dropdown-item.active {
  background-color: #c3cfd9;
}

.dropdown-menu-arrow.show {
  padding: 0px;
}

@media (max-width: 460px) {
  .center-phone {
    text-align: center !important;
  }

  .copyright {
    margin-bottom: 10px;
  }

  .footer-mobile {
    display: block !important;
    padding: 20px !important;
  }

  .mb-space {
    margin-bottom: 16px;
    display: block;
  }

  .divide-y > :not(template) ~ :not(template) {
    border-top: none !important;
    padding-top: 0 !important;
  }

  .divide-y > :not(template):not(:last-child) {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 700px) {
  .navbar-nav {
    float: left;
    width: 120px;
    margin-left: 5px !important;
    margin-top: 10px;
  }

  .navbar-expand-md .container-xl {
    display: block;
  }

  .order-first.order-md-last {
    float: right;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item {
    padding-left: 0px;
    text-align: center;
    display: block;
    padding-right: 0px;
    color: #1e293b;
    box-shadow: none;
  }

  .dropdown-menu {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .navbar-expand-md .navbar-collapse .dropdown-menu .dropdown-item.active {
    color: #206bc4;
  }

  .navbar .dropdown-menu {
    position: absolute !important;
    z-index: 1030;
    width: 100%;
    top: 45px;
  }

  .dropdown-menu-arrow:before {
    background-color: white;
  }
}

.center-phone {
  margin-top: 8px !important;
}
.no-avatar {
  border-radius: 50%;
}
section.detail {
  overflow: inherit;
}
.location-selected {
  font-size: 12px;
}
.ps-3 .icon {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  cursor: pointer;
}
.list-location-selected {
  max-height: 130px;
  overflow: auto;
}
#style-6::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

#style-6::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
  border: 1px solid yellow;
}

#style-6::-webkit-scrollbar-thumb {
  background-color: yellow;
}
</style>
<template>
  <div>
    <div
      v-if="
        ![
          'registry',
          'login',
          'email_active',
          'forgot_password_send_email',
          'forgot_password_update',
          'terms_of_service',
        ].includes(this.$route.name)
      "
    >
      <div class="navbar-expand-md">
        <div class="collapse navbar-collapse" id="navbar-menu">
          <div class="navbar navbar-dark">
            <div class="container-xl">
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <div class="nav-item dropdown">
                    <a
                      href="#"
                      class="btn btn-dark active w-100 dropdown align-text-top"
                      data-bs-toggle="dropdown"
                      aria-label="Open satellite menu"
                      aria-expanded="true"
                      @click="satelliteIsOpen = !satelliteIsOpen"
                    >
                      <div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-map-2"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <line x1="18" y1="6" x2="18" y2="6.01"></line>
                            <path
                              d="M18 13l-3.5 -5a4 4 0 1 1 7 0l-3.5 5"
                            ></path>
                            <polyline
                              points="10.5 4.75 9 4 3 7 3 20 9 17 15 20 21 17 21 15"
                            ></polyline>
                            <line x1="9" y1="4" x2="9" y2="17"></line>
                            <line x1="15" y1="15" x2="15" y2="20"></line>
                          </svg>
                          <a v-if="btnMapView == 'Map'">Street</a>
                          <a v-else>Satellite</a>
                        </div>
                      </div>
                    </a>
                    <div
                      class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
                      :class="{ show: satelliteIsOpen }"
                      data-bs-popper="static"
                    >
                      <a
                        href="#"
                        @click="onMapViewStyle('streets-v12')"
                        class="dropdown-item"
                        :class="{ active: btnMapView == 'Map' }"
                        >Street View</a
                      >
                      <a
                        href="#"
                        @click="onMapViewStyle('satellite-streets-v12')"
                        class="dropdown-item"
                        :class="{ active: btnMapView != 'Map' }"
                        >Satellite View</a
                      >
                    </div>
                  </div>
                </li>
                <li class="nav-item ps-3" style="display: none">
                  <form
                    action="./"
                    method="get"
                    autocomplete="off"
                    novalidate=""
                  >
                    <div class="input-icon">
                      <span class="input-icon-addon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <circle cx="10" cy="10" r="7"></circle>
                          <line x1="21" y1="21" x2="15" y2="15"></line>
                        </svg>
                      </span>
                      <input
                        type="text"
                        v-model="filter.search"
                        class="form-control"
                        placeholder="Search…"
                        aria-label="Search in website"
                        @input="onSearch()"
                      />
                    </div>
                  </form>
                </li>
              </ul>
              <div
                class="my-2 my-md-0 flex-grow-1 flex-md-grow-0 order-first order-md-last"
              >
                <img src="../../static/images/goaland.png" height="40" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page body -->
    <div class="page-body mb-0">
      <div class="row row-cards">
        <div class="col-lg-12 mt-0">
          <div class="mapbox-row">
            <div class="ratio vh-70">
              <div>
                <div id="map-simple" class="w-100 h-100"></div>
              </div>
            </div>
            <div class="selected-item" v-if="!ownerLandInfo">
              <div class="card card-sm">
                <div
                  class="card-body"
                  v-if="selectedLand && selectedLand.items.length"
                >
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-tabler icon-tabler-box"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <polyline
                          points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                        ></polyline>
                        <line x1="12" y1="12" x2="20" y2="7.5"></line>
                        <line x1="12" y1="12" x2="12" y2="21"></line>
                        <line x1="12" y1="12" x2="4" y2="7.5"></line>
                      </svg>
                    </div>
                    <div class="col">
                      <div class="font-weight-medium">
                        You have selected {{ selectedLand.items.length }} land
                        <template v-if="selectedLand.items.length == 1"
                          >plot.</template
                        >
                        <template v-else>plots.</template>
                      </div>
                      <div class="text-muted">
                        <i>Select multiple plots of land by clicking on it.</i>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-12">
                      <table class="my-3">
                        <tbody>
                          <tr>
                            <td>Plot Selected:</td>
                            <td class="ps-3">
                              {{ selectedLand.items.length }}
                            </td>
                          </tr>
                          <tr>
                            <td>Selling Price:</td>
                            <td class="ps-3">${{ data.priceTotal }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-12">
                      <a href="#detail" class="btn btn-outline-success me-3">
                        DETAIL
                      </a>
                      <a
                        @click="clearSelectionLand"
                        class="btn btn-outline-danger"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-trash"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <line x1="4" y1="7" x2="20" y2="7"></line>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                          <path
                            d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                          ></path>
                          <path
                            d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                          ></path>
                        </svg>
                        CLEAR SELECTION
                      </a>
                    </div>
                  </div>
                </div>

                <div class="card-body" v-else>
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-tabler icon-tabler-box"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <polyline
                          points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                        ></polyline>
                        <line x1="12" y1="12" x2="20" y2="7.5"></line>
                        <line x1="12" y1="12" x2="12" y2="21"></line>
                        <line x1="12" y1="12" x2="4" y2="7.5"></line>
                      </svg>
                    </div>
                    <div class="col">
                      <div class="font-weight-medium">No Tiles Selected</div>
                      <div class="text-muted">
                        <i>Select multiple plots of land by clicking on it.</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="selected-item">
              <div class="card card-sm">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-tabler icon-tabler-box"
                        width="36"
                        height="36"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <polyline
                          points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                        ></polyline>
                        <line x1="12" y1="12" x2="20" y2="7.5"></line>
                        <line x1="12" y1="12" x2="12" y2="21"></line>
                        <line x1="12" y1="12" x2="4" y2="7.5"></line>
                      </svg>
                    </div>
                    <div class="col">
                      <div class="font-weight-medium">
                        {{ ownerLandInfo.lands.length }} Tiles Selected
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div id="style-6" class="col-lg-12 list-location-selected">
                      <table class="my-3">
                        <tbody>
                          <tr
                            v-for="(item, index) in ownerLandInfo.lands"
                            v-bind:key="index"
                          >
                            <td class="location-selected">
                              {{ getLocation(item.location) }}
                            </td>
                            <td class="ps-3">
                              <svg
                                v-on:click="findAddressSelected(item.location)"
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-search"
                                width="12"
                                height="12"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path
                                  d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"
                                ></path>
                                <path d="M21 21l-6 -6"></path>
                              </svg>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-lg-12">
                      <a href="#detail" class="btn btn-outline-success me-3">
                        DETAIL
                      </a>
                      <a @click="clearSelection" class="btn btn-outline-danger">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-trash"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <line x1="4" y1="7" x2="20" y2="7"></line>
                          <line x1="10" y1="11" x2="10" y2="17"></line>
                          <line x1="14" y1="11" x2="14" y2="17"></line>
                          <path
                            d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"
                          ></path>
                          <path
                            d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"
                          ></path>
                        </svg>
                        CLEAR SELECTION
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--<div class="col-lg-2">                                                                                                                                                                                   </div>-->
      </div>
    </div>

    <section
      v-if="!ownerLandInfo && !(selectedLand && selectedLand.items.length)"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="row text-center text-muted">
              <h2>Browse and select the land plots you want</h2>
              <p>
                <b>Shift + Left Click</b> to select multiple plots of land
                easily. <b>Right Click</b> to end.
                <br />
                Maximum of 300 plots allowed per purchase.
              </p>
            </div>
            <p class="text-center">
              <a
                @click="showTopupCreditDialog()"
                class="btn btn-xs btn-default bg-blue text-white"
                >BUY LAND CREDITS</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>

    <section v-if="ownerLandInfo" id="detail" class="detail">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row g-2 align-items-center mb-space">
              <div class="col-auto">
                <img class="land-icon" src="@/assets/images/default-land.png" />
              </div>
              <div class="col">
                <div class="align-items-baseline">
                  <div
                    style="
                      margin-bottom: 5px !important;
                      float: left;
                      width: 100%;
                    "
                    class="mb-0 me-2"
                  >
                    <span
                      v-if="ownerLandInfo.ownerAvatar != ''"
                      class="avatar avatar-sm no-avatar"
                      data-v-3b1ca699=""
                      :style="
                        'width: 25px;height: 25px;float: left;background-image: url(' +
                        ownerLandInfo.ownerAvatar +
                        ');'
                      "
                    ></span>
                    <span class="avatar-wallet" v-else
                      ><svg
                        style="color: rgb(32, 107, 196)"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-wallet"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        data-v-3b1ca699=""
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                          data-v-3b1ca699=""
                        ></path>
                        <path
                          d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
                          data-v-3b1ca699=""
                        ></path>
                        <path
                          d="M20 12v4h-4a2 2 0 0 1 0 -4h4"
                          data-v-3b1ca699=""
                        ></path></svg
                    ></span>
                    <a style="color: #206bc4; margin-left: 5px">{{
                      ownerLandInfo.ownerName
                    }}</a>
                  </div>
                  <div class="h1 mb-0 me-2">
                    {{ ownerLandInfo.address }}
                  </div>
                  <!-- <div class="mb-0 me-2">
                                    <b class="h3 text-blue">
                                        {{ ownerLandInfo.location }}
                                    </b>
                                </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="ms-auto lh-3 mb-2 mb-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-box"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline
                  points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                ></polyline>
                <line x1="12" y1="12" x2="20" y2="7.5"></line>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <line x1="12" y1="12" x2="4" y2="7.5"></line>
              </svg>
              <b> Tiles Selected: {{ ownerLandInfo.lands.length }}</b>
            </div>
            <div class="d-flex mb-2 w-50">
              <strong>Land Rating</strong>
              <span class="tooltip">
                <sup class="ms-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-tabler icon-tabler-info-circle"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                    <polyline points="11 12 12 12 12 16 13 16"></polyline>
                  </svg>
                </sup>
                <span class="tooltiptext"
                  >This rating reflects the desirability of the land plot based
                  on an algorithm of the land’s earning potential. We utilize
                  data from the game.</span
                >
              </span>
            </div>
            <div class="start mb-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="col-lg-3">
            <table>
              <tbody>
                <tr class="mb-space">
                  <th>Market Value:</th>
                  <th class="ps-3">
                    <a>${{ 100 * ownerLandInfo.lands.length }}</a>
                    <span class="tooltip">
                      <span class="tooltiptext"
                        >This reflects the valuation of this land plot based on
                        an algorithm of the land’s earning potential. We utilize
                        data from the game such as total number of transactions
                        and the number of buildings on the land plot. This value
                        will fluctuate, but valuation will always be greater
                        than $100.</span
                      >
                      <sup class="ms-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-tabler icon-tabler-info-circle"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <circle cx="12" cy="12" r="9"></circle>
                          <line x1="12" y1="8" x2="12.01" y2="8"></line>
                          <polyline points="11 12 12 12 12 16 13 16"></polyline>
                        </svg>
                      </sup>
                    </span>
                  </th>
                </tr>
                <tr v-if="ownerLandInfo.isMinted">
                  <th>
                    <a
                      target="_bank"
                      v-bind:href="OPENSEA + ownerLandInfo.uriid"
                      ><img
                        src="../assets/images/nft.png"
                        width="35"
                        alt="Minted NFT"
                    /></a>
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>

    <section
      id="detail"
      class="detail"
      v-if="!ownerLandInfo && selectedLand && selectedLand.items.length"
      style="overflow: visible"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row g-2 align-items-center mb-space">
              <div class="col-auto">
                <img class="land-icon" src="@/assets/images/default-land.png" />
              </div>
              <div class="col">
                <div class="align-items-baseline">
                  <div
                    class="h1 mb-0 me-2"
                    v-if="
                      selectedLand.items[selectedLand.items.length - 1].areas
                    "
                  >
                    {{
                      selectedLand.items[selectedLand.items.length - 1].areas
                        .district
                    }}
                  </div>
                  <div class="mb-0 me-2">
                    <b class="h3 text-blue">
                      {{ getCenter(selectedLand.items).join(", ") }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ms-auto lh-3 mb-2 mb-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-box"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <polyline
                  points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"
                ></polyline>
                <line x1="12" y1="12" x2="20" y2="7.5"></line>
                <line x1="12" y1="12" x2="12" y2="21"></line>
                <line x1="12" y1="12" x2="4" y2="7.5"></line>
              </svg>
              <b> Tiles Selected: {{ selectedLand.items.length }}</b>
            </div>
            <div class="d-flex mb-2 w-50">
              <strong>Land Rating</strong>
              <span class="tooltip">
                <sup class="ms-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-tabler icon-tabler-info-circle"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                    <polyline points="11 12 12 12 12 16 13 16"></polyline>
                  </svg>
                </sup>
                <span class="tooltiptext"
                  >This rating reflects the desirability of the land plot based
                  on an algorithm of the land’s earning potential. We utilize
                  data from the game.</span
                >
              </span>
            </div>
            <div class="start mb-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon-tabler icon-tabler-star"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"
                ></path>
              </svg>
            </div>
          </div>

          <div class="col-lg-3">
            <table>
              <tbody>
                <tr class="mb-space">
                  <th>Market Value:</th>
                  <th class="ps-3">
                    <s>${{ data.totalsub }}</s>
                    <span class="tooltip">
                      <span class="tooltiptext"
                        >This reflects the valuation of this land plot based on
                        an algorithm of the land’s earning potential. We utilize
                        data from the game such as total number of transactions
                        and the number of buildings on the land plot. This value
                        will fluctuate, but valuation will always be greater
                        than $100.</span
                      >
                      <sup class="ms-auto">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon-tabler icon-tabler-info-circle"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <circle cx="12" cy="12" r="9"></circle>
                          <line x1="12" y1="8" x2="12.01" y2="8"></line>
                          <polyline points="11 12 12 12 12 16 13 16"></polyline>
                        </svg>
                      </sup>
                    </span>
                  </th>
                </tr>
                <tr class="mb-space">
                  <th>Selling Price:</th>
                  <th class="ps-3">${{ data.priceTotal }}</th>
                  <!--or 1 Credits-->
                </tr>
              </tbody>
            </table>
            <div class="text-azure mb-space" v-if="data.missingCount">
              Select {{ data.missingCount }} more plot<span
                v-if="data.missingCount > 1"
                >s</span
              >
              and get {{ data.nextDiscount }}% off
            </div>
            <div>
              <a class="btn btn-primary mt-1" @click="addToCart()">
                ADD TO CART
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="analysis"
      class="analysis"
      v-if="ownerLandInfo || (selectedLand && selectedLand.items.length)"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h4>LAND ANALYSIS</h4>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-8 divide-y">
                    <div class="row">
                      <div class="col-lg-4 mb-space">
                        <p>
                          <b>Number of Buildings</b>
                        </p>
                        <p class="d-flex mb-2">
                          <span>No information yet</span>
                          <span class="tooltip">
                            <span class="tooltiptext"
                              >The total number of buildings in the game
                              situated on this land plot.</span
                            >

                            <sup>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-tabler icon-tabler-info-circle"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <circle cx="12" cy="12" r="9"></circle>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                <polyline
                                  points="11 12 12 12 12 16 13 16"
                                ></polyline>
                              </svg>
                            </sup>
                          </span>
                        </p>
                      </div>
                      <div class="col-lg-4 mb-space">
                        <p>
                          <b>Average Daily Transactions</b>
                        </p>
                        <p class="d-flex mb-2">
                          <span>No information yet</span>
                          <span class="tooltip">
                            <span class="tooltiptext"
                              >The average daily transactions on this land plot,
                              based on in-game data.</span
                            >
                            <sup>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-tabler icon-tabler-info-circle"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <circle cx="12" cy="12" r="9"></circle>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                <polyline
                                  points="11 12 12 12 12 16 13 16"
                                ></polyline>
                              </svg>
                            </sup>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 mb-space">
                        <p>
                          <b>Total Transaction Volume</b>
                        </p>
                        <p class="d-flex mb-2 mb-space">
                          <span>No information yet</span>
                          <span class="tooltip">
                            <span class="tooltiptext"
                              >The total amount of transactions on this land
                              plot since the launch of GOALAND, based on in-game
                              data.</span
                            >
                            <sup>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-tabler icon-tabler-info-circle"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <circle cx="12" cy="12" r="9"></circle>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                <polyline
                                  points="11 12 12 12 12 16 13 16"
                                ></polyline>
                              </svg>
                            </sup>
                          </span>
                        </p>
                      </div>
                      <div class="col-lg-4 mb-space">
                        <p>
                          <b>Estimated Daily Earnings</b>
                        </p>
                        <p class="d-flex mb-2 mb-space">
                          <span>No information yet</span>
                          <span class="tooltip">
                            <span class="tooltiptext"
                              >Using data from the game, we estimate the daily
                              earnings for this land plot. The way data is
                              collected, the frequency of its collection, and
                              the way this data interacts with our algorithms
                              are subject to fluctuations, and therefore, this
                              figure may not be accurate on a real-time
                              basis.</span
                            >
                            <sup>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon-tabler icon-tabler-info-circle"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <circle cx="12" cy="12" r="9"></circle>
                                <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                <polyline
                                  points="11 12 12 12 12 16 13 16"
                                ></polyline>
                              </svg>
                            </sup>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div id="chart-completion-tasks-2">
                      <div
                        id="apexchartsqdnn2s4ri"
                        class="apexcharts-canvas apexchartsqdnn2s4ri apexcharts-theme-light"
                        style="width: 100%; height: 240px"
                      >
                        <svg
                          id="SvgjsSvg1573"
                          width="100%"
                          height="240"
                          xmlns="http://www.w3.org/2000/svg"
                          version="1.1"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          xmlns:svgjs="http://svgjs.dev"
                          class="apexcharts-svg apexcharts-zoomable hovering-zoom"
                          xmlns:data="ApexChartsNS"
                          transform="translate(0, 0)"
                          style="background: transparent"
                        >
                          <g
                            id="SvgjsG1575"
                            class="apexcharts-inner apexcharts-graphical"
                            transform="translate(31.859375, 10)"
                          >
                            <defs id="SvgjsDefs1574">
                              <clipPath id="gridRectMaskqdnn2s4ri">
                                <rect
                                  id="SvgjsRect1585"
                                  width="345.140625"
                                  height="203"
                                  x="-3"
                                  y="-1"
                                  rx="0"
                                  ry="0"
                                  opacity="1"
                                  stroke-width="0"
                                  stroke="none"
                                  stroke-dasharray="0"
                                  fill="#fff"
                                ></rect>
                              </clipPath>
                              <clipPath id="forecastMaskqdnn2s4ri"></clipPath>
                              <clipPath
                                id="nonForecastMaskqdnn2s4ri"
                              ></clipPath>
                              <clipPath id="gridRectMarkerMaskqdnn2s4ri">
                                <rect
                                  id="SvgjsRect1586"
                                  width="343.140625"
                                  height="205"
                                  x="-2"
                                  y="-2"
                                  rx="0"
                                  ry="0"
                                  opacity="1"
                                  stroke-width="0"
                                  stroke="none"
                                  stroke-dasharray="0"
                                  fill="#fff"
                                ></rect>
                              </clipPath>
                            </defs>
                            <line
                              id="SvgjsLine1581"
                              x1="56.0234375"
                              y1="0"
                              x2="56.0234375"
                              y2="201"
                              stroke="#b6b6b6"
                              stroke-dasharray="3"
                              stroke-linecap="butt"
                              class="apexcharts-xcrosshairs"
                              x="56.0234375"
                              y="0"
                              width="1"
                              height="201"
                              fill="#b1b9c4"
                              filter="none"
                              fill-opacity="0.9"
                              stroke-width="1"
                            ></line>
                            <line
                              id="SvgjsLine1596"
                              x1="0"
                              y1="202"
                              x2="0"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <line
                              id="SvgjsLine1597"
                              x1="56.5234375"
                              y1="202"
                              x2="56.5234375"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <line
                              id="SvgjsLine1598"
                              x1="113.046875"
                              y1="202"
                              x2="113.046875"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <line
                              id="SvgjsLine1599"
                              x1="169.5703125"
                              y1="202"
                              x2="169.5703125"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <line
                              id="SvgjsLine1600"
                              x1="226.09375"
                              y1="202"
                              x2="226.09375"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <line
                              id="SvgjsLine1601"
                              x1="282.6171875"
                              y1="202"
                              x2="282.6171875"
                              y2="208"
                              stroke="#e0e0e0"
                              stroke-dasharray="0"
                              stroke-linecap="butt"
                              class="apexcharts-xaxis-tick"
                            ></line>
                            <g id="SvgjsG1592" class="apexcharts-grid">
                              <g
                                id="SvgjsG1593"
                                class="apexcharts-gridlines-horizontal"
                              >
                                <line
                                  id="SvgjsLine1603"
                                  x1="0"
                                  y1="40.2"
                                  x2="339.140625"
                                  y2="40.2"
                                  stroke="#e0e0e0"
                                  stroke-dasharray="4"
                                  stroke-linecap="butt"
                                  class="apexcharts-gridline"
                                ></line>
                                <line
                                  id="SvgjsLine1604"
                                  x1="0"
                                  y1="80.4"
                                  x2="339.140625"
                                  y2="80.4"
                                  stroke="#e0e0e0"
                                  stroke-dasharray="4"
                                  stroke-linecap="butt"
                                  class="apexcharts-gridline"
                                ></line>
                                <line
                                  id="SvgjsLine1605"
                                  x1="0"
                                  y1="120.60000000000001"
                                  x2="339.140625"
                                  y2="120.60000000000001"
                                  stroke="#e0e0e0"
                                  stroke-dasharray="4"
                                  stroke-linecap="butt"
                                  class="apexcharts-gridline"
                                ></line>
                                <line
                                  id="SvgjsLine1606"
                                  x1="0"
                                  y1="160.8"
                                  x2="339.140625"
                                  y2="160.8"
                                  stroke="#e0e0e0"
                                  stroke-dasharray="4"
                                  stroke-linecap="butt"
                                  class="apexcharts-gridline"
                                ></line>
                              </g>
                              <g
                                id="SvgjsG1594"
                                class="apexcharts-gridlines-vertical"
                              ></g>
                              <line
                                id="SvgjsLine1609"
                                x1="0"
                                y1="201"
                                x2="339.140625"
                                y2="201"
                                stroke="transparent"
                                stroke-dasharray="0"
                                stroke-linecap="butt"
                              ></line>
                              <line
                                id="SvgjsLine1608"
                                x1="0"
                                y1="1"
                                x2="0"
                                y2="201"
                                stroke="transparent"
                                stroke-dasharray="0"
                                stroke-linecap="butt"
                              ></line>
                            </g>
                            <g
                              id="SvgjsG1587"
                              class="apexcharts-line-series apexcharts-plot-series"
                            >
                              <g
                                id="SvgjsG1588"
                                class="apexcharts-series"
                                seriesName="Tasksxcompletion"
                                data:longestSeries="true"
                                rel="1"
                                data:realIndex="0"
                              >
                                <path
                                  id="SvgjsPath1591"
                                  d="M 0 138.69C 19.783203125 138.69 36.740234375 174.87 56.5234375 174.87C 76.306640625 174.87 93.263671875 14.069999999999993 113.046875 14.069999999999993C 132.830078125 14.069999999999993 149.787109375 94.46999999999998 169.5703125 94.46999999999998C 189.353515625 94.46999999999998 206.310546875 110.55 226.09375 110.55C 245.876953125 110.55 262.833984375 70.35 282.6171875 70.35C 302.400390625 70.35 319.357421875 168.84 339.140625 168.84"
                                  fill="none"
                                  fill-opacity="1"
                                  stroke="rgba(32,107,196,1)"
                                  stroke-opacity="1"
                                  stroke-linecap="round"
                                  stroke-width="2"
                                  stroke-dasharray="0"
                                  class="apexcharts-line"
                                  index="0"
                                  clip-path="url(#gridRectMaskqdnn2s4ri)"
                                  pathTo="M 0 138.69C 19.783203125 138.69 36.740234375 174.87 56.5234375 174.87C 76.306640625 174.87 93.263671875 14.069999999999993 113.046875 14.069999999999993C 132.830078125 14.069999999999993 149.787109375 94.46999999999998 169.5703125 94.46999999999998C 189.353515625 94.46999999999998 206.310546875 110.55 226.09375 110.55C 245.876953125 110.55 262.833984375 70.35 282.6171875 70.35C 302.400390625 70.35 319.357421875 168.84 339.140625 168.84"
                                  pathFrom="M -1 201 L -1 201 L 56.5234375 201 L 113.046875 201 L 169.5703125 201 L 226.09375 201 L 282.6171875 201 L 339.140625 201"
                                  fill-rule="evenodd"
                                ></path>
                                <g
                                  id="SvgjsG1589"
                                  class="apexcharts-series-markers-wrap"
                                  data:realIndex="0"
                                >
                                  <g class="apexcharts-series-markers">
                                    <circle
                                      id="SvgjsCircle1659"
                                      r="0"
                                      cx="56.5234375"
                                      cy="174.87"
                                      class="apexcharts-marker w30xpdtcq no-pointer-events"
                                      stroke="#ffffff"
                                      fill="#206bc4"
                                      fill-opacity="1"
                                      stroke-width="2"
                                      stroke-opacity="0.9"
                                      default-marker-size="0"
                                    ></circle>
                                  </g>
                                </g>
                              </g>
                              <g
                                id="SvgjsG1590"
                                class="apexcharts-datalabels"
                                data:realIndex="0"
                              ></g>
                            </g>
                            <g id="SvgjsG1595" class="apexcharts-grid-borders">
                              <line
                                id="SvgjsLine1602"
                                x1="0"
                                y1="0"
                                x2="339.140625"
                                y2="0"
                                stroke="#e0e0e0"
                                stroke-dasharray="4"
                                stroke-linecap="butt"
                                class="apexcharts-gridline"
                              ></line>
                              <line
                                id="SvgjsLine1607"
                                x1="0"
                                y1="201"
                                x2="339.140625"
                                y2="201"
                                stroke="#e0e0e0"
                                stroke-dasharray="4"
                                stroke-linecap="butt"
                                class="apexcharts-gridline"
                              ></line>
                              <line
                                id="SvgjsLine1633"
                                x1="0"
                                y1="202"
                                x2="339.140625"
                                y2="202"
                                stroke="#e0e0e0"
                                stroke-dasharray="0"
                                stroke-width="1"
                                stroke-linecap="butt"
                              ></line>
                            </g>
                            <line
                              id="SvgjsLine1654"
                              x1="0"
                              y1="0"
                              x2="339.140625"
                              y2="0"
                              stroke="#b6b6b6"
                              stroke-dasharray="0"
                              stroke-width="1"
                              stroke-linecap="butt"
                              class="apexcharts-ycrosshairs"
                            ></line>
                            <line
                              id="SvgjsLine1655"
                              x1="0"
                              y1="0"
                              x2="339.140625"
                              y2="0"
                              stroke-dasharray="0"
                              stroke-width="0"
                              stroke-linecap="butt"
                              class="apexcharts-ycrosshairs-hidden"
                            ></line>
                            <g
                              id="SvgjsG1656"
                              class="apexcharts-yaxis-annotations"
                            ></g>
                            <g
                              id="SvgjsG1657"
                              class="apexcharts-xaxis-annotations"
                            ></g>
                            <g
                              id="SvgjsG1658"
                              class="apexcharts-point-annotations"
                            ></g>
                            <rect
                              id="SvgjsRect1660"
                              width="0"
                              height="0"
                              x="0"
                              y="0"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fefefe"
                              class="apexcharts-zoom-rect"
                            ></rect>
                            <rect
                              id="SvgjsRect1661"
                              width="0"
                              height="0"
                              x="0"
                              y="0"
                              rx="0"
                              ry="0"
                              opacity="1"
                              stroke-width="0"
                              stroke="none"
                              stroke-dasharray="0"
                              fill="#fefefe"
                              class="apexcharts-selection-rect"
                            ></rect>
                          </g>
                          <rect
                            id="SvgjsRect1580"
                            width="0"
                            height="0"
                            x="0"
                            y="0"
                            rx="0"
                            ry="0"
                            opacity="1"
                            stroke-width="0"
                            stroke="none"
                            stroke-dasharray="0"
                            fill="#fefefe"
                          ></rect>
                          <g
                            id="SvgjsG1634"
                            class="apexcharts-yaxis"
                            rel="0"
                            transform="translate(17.859375, 0)"
                          >
                            <g id="SvgjsG1635" class="apexcharts-yaxis-texts-g">
                              <text
                                id="SvgjsText1637"
                                font-family="inherit"
                                x="4"
                                y="11.5"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1638">500</tspan>
                                <title>500</title>
                              </text>
                              <text
                                id="SvgjsText1640"
                                font-family="inherit"
                                x="4"
                                y="51.7"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1641">400</tspan>
                                <title>400</title>
                              </text>
                              <text
                                id="SvgjsText1643"
                                font-family="inherit"
                                x="4"
                                y="91.9"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1644">300</tspan>
                                <title>300</title>
                              </text>
                              <text
                                id="SvgjsText1646"
                                font-family="inherit"
                                x="4"
                                y="132.10000000000002"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1647">200</tspan>
                                <title>200</title>
                              </text>
                              <text
                                id="SvgjsText1649"
                                font-family="inherit"
                                x="4"
                                y="172.3"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1650">100</tspan>
                                <title>100</title>
                              </text>
                              <text
                                id="SvgjsText1652"
                                font-family="inherit"
                                x="4"
                                y="212.5"
                                text-anchor="end"
                                dominant-baseline="auto"
                                font-size="11px"
                                font-weight="400"
                                fill="#373d3f"
                                class="apexcharts-text apexcharts-yaxis-label"
                                style="font-family: inherit"
                              >
                                <tspan id="SvgjsTspan1653">0</tspan>
                                <title>0</title>
                              </text>
                            </g>
                          </g>
                          <g id="SvgjsG1576" class="apexcharts-annotations"></g>
                        </svg>

                        <div class="notice-not-available text-muted">
                          Chart not available yet
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      id="information"
      class="information"
      v-if="!ownerLandInfo && selectedLand && selectedLand.items.length"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h4>LAND INFORMATION</h4>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="card">
              <div class="card-table table-responsive">
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <td><b>Land ID (Coordinates)</b></td>
                      <td><b>Country</b></td>
                      <td><b>Status</b></td>
                      <td><b>Owner</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in selectedLand.items"
                      v-bind:key="index"
                    >
                      <td>
                        {{ getCenterItemBox(item.geometry.bbox).join(", ") }}
                      </td>
                      <td class="">
                        <span v-if="item.areas">{{ item.areas.country }}</span>
                      </td>
                      <td class="">Available</td>
                      <td class="">-</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="ownerLandInfo" id="information" class="information">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title">
              <h4>LAND INFORMATION</h4>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="card">
              <div class="card-table table-responsive">
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <td><b>Land ID (Coordinates)</b></td>
                      <td><b>Country</b></td>
                      <td><b>Status</b></td>
                      <td><b>Owner</b></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in ownerLandInfo.lands"
                      v-bind:key="index"
                    >
                      <td>
                        {{ getLocation(item.location) }}
                      </td>
                      <td class="">
                        <span>{{ item.country }}</span>
                      </td>
                      <td class="">-</td>
                      <td class="">{{ ownerLandInfo.ownerName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div
      class="modal modal-blur fade show"
      id="payment-method"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      style="display: block"
      v-if="checkoutStatus"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Buy Land Plots</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeCheckout"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-5">
                <div>
                  <a
                    class="btn btn-primary active w-100 mb-2 py-3"
                    @click="doBuyLandWithWert"
                  >
                    <div class="w-100">
                      <div class="float-start">CREDIT / DEBIT CARD</div>
                      <div class="float-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-credit-card me-0"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <rect
                            x="3"
                            y="5"
                            width="18"
                            height="14"
                            rx="3"
                          ></rect>
                          <line x1="3" y1="10" x2="21" y2="10"></line>
                          <line x1="7" y1="15" x2="7.01" y2="15"></line>
                          <line x1="11" y1="15" x2="13" y2="15"></line>
                        </svg>
                      </div>
                    </div>
                  </a>
                  <p class="minsize">
                    Payment processed by Wert. Final sale prices will be based
                    on Wert's real-time USD/ETH exchange rate. A 4% transaction
                    fee will be applied towards your purchase.
                  </p>
                </div>

                <div>
                  <a
                    class="btn btn-primary active w-100 mb-2 py-3"
                    @click="doBuyLandWithETH"
                  >
                    <div class="w-100">
                      <div class="float-start">CRYPTOCURRENCY</div>
                      <div class="float-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-currency-bitcoin me-0"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8"
                          ></path>
                          <line x1="8" y1="6" x2="8" y2="18"></line>
                          <line x1="8" y1="12" x2="14" y2="12"></line>
                          <line x1="9" y1="3" x2="9" y2="6"></line>
                          <line x1="13" y1="3" x2="13" y2="6"></line>
                          <line x1="9" y1="18" x2="9" y2="21"></line>
                          <line x1="13" y1="18" x2="13" y2="21"></line>
                        </svg>
                      </div>
                    </div>
                  </a>
                  <p class="minsize">
                    Pay directly in crypto from your wallet. Pay only gas fees,
                    no transaction fee required.
                  </p>
                </div>

                <div>
                  <a
                    @click="doBuyLandWithLandcredit"
                    class="btn btn-primary active w-100 mb-2 py-3"
                  >
                    <div class="w-100">
                      <div class="float-start">LAND CREDITS</div>
                      <div class="float-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-wallet me-0"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path
                            stroke="none"
                            d="M0 0h24v24H0z"
                            fill="none"
                          ></path>
                          <path
                            d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
                          ></path>
                          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                        </svg>
                      </div>
                    </div>
                  </a>
                  <p class="minsize">
                    Redeem land credits for land plots. (1 land credit = 1 land
                    plot)
                  </p>
                </div>
              </div>
              <div class="col-sm-12 col-md-7">
                <div class="invoice-wrapper">
                  <div class="invoice">
                    <p>
                      Total Land <span>{{ totalItems }}</span>
                    </p>
                  </div>
                  <hr class="hr-padding" />
                  <div class="invoice">
                    <p class="mb-1">
                      <b>Sub Total</b
                      ><span>{{ currencyFormat(data.totalsub) }}</span>
                    </p>
                  </div>
                  <div class="invoice text-red">
                    <p>
                      <b>You Save</b>
                      <span>-{{ currencyFormat(itemsPrice.discount) }}</span>
                    </p>
                  </div>
                  <div class="invoice" style="font-size: 25px">
                    <p class="m-0">
                      <b>Total</b>
                      <span>
                        <b>{{ currencyFormat(totalPrice) }}</b> </span
                      ><br />
                    </p>
                  </div>
                  <div class="invoice">
                    <p>
                      <b>&nbsp;</b>
                      <span>
                        or {{ totalItems }} land credit<span
                          v-if="totalItems > 1"
                          >s</span
                        ></span
                      >
                    </p>
                  </div>

                  <hr class="hr-padding" />

                  <div
                    class="text-right text-blue"
                    v-if="itemsPrice.missingCount"
                  >
                    <b
                      >Select {{ itemsPrice.missingCount }} more plots and get
                      {{ itemsPrice.nextDiscount }}% off.</b
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal modal-blur fade show"
      id="modal_transaction"
      tabindex="-1"
      style="display: block"
      aria-modal="true"
      role="dialog"
      v-if="modal_transaction"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close"
            @click="modal_transaction = !modal_transaction"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-status bg-success"></div>
          <div class="modal-body text-center py-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon mb-2 text-green icon-lg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <circle cx="12" cy="12" r="9"></circle>
              <path d="M9 12l2 2l4 -4"></path>
            </svg>
            <h3>Payment succedeed</h3>

            <div class="mt-3">
              <table class="table table-transparent table-responsive">
                <tbody>
                  <tr>
                    <td class="strong text-start">Network</td>
                    <td class="text-end">
                      <a :href="ExplorerURL" target="_blank">
                        {{ NETWORK }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="strong text-start">Contract Address</td>
                    <td class="text-end">
                      <a
                        :href="ExplorerURL + '/address/' + contractAddress"
                        target="_blank"
                      >
                        {{ contractAddress }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td class="strong text-start">Transaction Hash</td>
                    <td class="text-end">
                      <a
                        :href="
                          ExplorerURL + '/tx/' + contractResult.transactionHash
                        "
                        target="_blank"
                      >
                        {{ contractResult.transactionHash }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="section" style="background-color: #1e293b">
      <div class="container">
        <div class="row">
          <div class="col-md-6 pr-md-7 center-phone">
            <p class="mb-1" style="color: #e3e3e3">REQUIRE SUPPORT?</p>
            <p class="h2" style="color: #ffffff">HERE'S HOW WE CAN HELP</p>
          </div>

          <div class="col-md-6 mt-5 mt-md-0 text-right center-phone">
            <a
              href="https://discord.com/invite/thetipsycompany"
              target="_blank"
              data-custom-highlight-color=""
              type="submit"
              class="btn btn-blue mx-3 px-4 my-2"
              >Join Discord</a
            >
            <a
              href="https://wiki.gateofabyss.com/gateofabyss/gate-of-abyss-land/land-overview"
              target="_blank"
              data-custom-highlight-color=""
              type="submit"
              class="btn btn-default mx-2 px-4 my-2"
              >Documentation</a
            >
          </div>
        </div>
      </div>
    </section>

    <footer id="footer" class="text-white">
      <div class="d-flex py-4 px-5 footer-mobile">
        <div class="copyright">
          <span
            >©2022 Gate of Abyss by The Tipsy Company. A registered entity under
            the Republic of the Marshall Islands (Reg. No. 114167).</span
          >
        </div>
        <div class="ms-auto">
          <a>All prices are in USD</a> |
          <a
            style="color: #4f9fff"
            href="https://gateofabyss.com/terms-and-conditions/"
            target="_blank"
            >Terms & Conditions</a
          >
          |
          <a
            style="color: #4f9fff"
            href="https://gateofabyss.com/privacy-policy/"
            target="_blank"
            >Privacy Policy</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
//import {getGrid} from "./calc";
import { Grid, GRID_CLICK_EVENT, GRID_SELECT_EVENT } from "./grid";
import axios from "axios";
// import Select2 from 'vue3-select2-component';
import { point, midpoint, distance } from "@turf/turf";
import * as turf from "@turf/turf";
let map = undefined;
var selectedCells = [];
var readyItems = [];
var readyItemsOwner = [];
var readyItemsSelected = [];
var readyItemsOwnerRemove = [];
var timer;
var collectionsAll = [];
import { mapState } from "vuex";
import Web3 from "web3";

let isShiftButton = false;
let logClickPoint = [];

export default {
  components: {
    // Select2
  },
  data() {
    return {
      ownerLandInfo: undefined,
      assets: this.$assets,
      selectedLand: undefined,
      count: 1,
      show_payment_method: false,
      show_cart: false,
      satelliteIsOpen: false,
      btnMapView: "Map",

      chartData: {
        labels: ["January", "February", "March"],
        datasets: [{ data: [40, 20, 12] }],
      },
      chartOptions: {
        responsive: true,
      },
      filter: {
        search: "",
      },

      data: {
        totalsub: 0,
        discount: 0,
        priceTotal: 0,
        nextDiscount: 0,
        missingCount: 0,
        discountPercent: 0,
      },
      myValue: undefined,
      myOptions: [
        {
          id: 1,
          text: "Option 1",
        },
        {
          id: 2,
          text: "Option 2",
        },
        {
          id: 3,
          text: "Option 3",
        },
      ],

      connected: false,
      contractResult: [],
      networkId: "",
      address: "",
      balance: "",
      contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
      targetNetworkId: process.env.VUE_APP_NETWORK_ID,
      ExplorerURL: process.env.VUE_APP_EXPLORER_URL,
      NETWORK: process.env.VUE_APP_NETWORK,
      OPENSEA: process.env.VUE_APP_OPENSEA,
      modal_transaction: false,
      access_token_mapbox:
        "pk.eyJ1IjoiZGVpcGFyb3VzIiwiYSI6ImNsY29pYWhicDFjeGczbm84MzA0Ynk1czkifQ.iVsD87dg6-t4eFzrUAkCeQ",
      areas: undefined,
    };
  },

  methods: {
    getBBoxLocation: function (bBox) {
      return bBox[1] + ",  " + bBox[0] + ",  " + bBox[3] + ",  " + bBox[2];
    },
    clearSelection: function () {
      let self = this;
      self.ownerLandInfo = null;
      const source = map.getSource("ready-cells-selected");
      source.setData({
        type: "FeatureCollection",
        features: [],
      });

      readyItemsOwner = readyItemsOwner.concat(readyItemsOwnerRemove);
      readyItemsOwnerRemove = [];
      const sourceOwnerSet = map.getSource("ready-cells-owner");
      sourceOwnerSet.setData({
        type: "FeatureCollection",
        features: readyItemsOwner,
      });
    },
    findAddressSelected: function (location) {
      let self = this;
      var valueLatLng = location;
      var a_valueLatLng = valueLatLng.split(",");
      if (a_valueLatLng.length < 2) {
        return;
      }
      if (!self.isLatitude(a_valueLatLng[1])) {
        return;
      }

      if (!self.isLongitude(a_valueLatLng[0])) {
        return;
      }
      map.setZoom(13);
      map.flyTo({
        center: [a_valueLatLng[0], a_valueLatLng[1]],
      });
    },
    currencyFormat(v) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(v > 0 ? v : 0);
    },

    getCenter(arrBBox) {
      console.log(arrBBox, "arrBBox");
      if (arrBBox) {
        let fi = arrBBox[arrBBox.length - 1].geometry.bbox;
        return [(fi[1] + fi[3]) / 2, (fi[0] + fi[2]) / 2];
      }
      return [0, 0];
    },

    getCenterItemBox(arrBBox) {
      if (arrBBox) {
        let fi = arrBBox;
        return [(fi[1] + fi[3]) / 2, (fi[0] + fi[2]) / 2];
      }
      return [0, 0];
    },

    showTopupCreditDialog() {
      this.$store.dispatch("landCreditTopup/showModal", {});
    },

    /***
     * Do buy land regions
     */

    // Buy land with credit
    doBuyLandWithLandcredit() {
      if (!this.status.loggedIn) {
        this.$swal("Please login to access", "Require login", "error");
        return;
      }
      if (this.itemsOnCart.length == 0) {
        return;
      }
      let amount = this.itemsOnCart.length;
      this.$swal
        .fire({
          title:
            amount == 1
              ? `Are you sure you want to convert 1 land credit to 1 land plot?`
              : `Are you sure you want to convert ${amount} land credits to ${amount} land plots?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Confirm",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("cart/creditPayment");
          }
        });
    },
    async closeCheckout() {
      this.$store.dispatch("cart/closeCheckoutDialog");
    },

    doBuyLandWithWert() {
      // Init payment with wertIO
      this.$store.dispatch("cart/showWertNotice");
    },

    doBuyLand() {
      if (!this.status.loggedIn) {
        this.$swal("Please login to access", "Require login", "error");
        return;
      }

      if (selectedCells.length == 0) {
        return;
      }

      this.$swal
        .fire({
          title: "Do you want to buy it ?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Confirm",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          if (!result.isConfirmed) {
            return;
          }
        });
    },
    // FetchLands
    fetchLands() {
      // Request to server fech all buyed land

      let zoom = map.getZoom();
      if (zoom < 11 || zoom > 19) {
        let src = map.getSource("ready-cells");
        if (src)
          src.setData({
            type: "FeatureCollection",
            features: [],
          });
        // var sourceSelect = map.getSource("ready-cells-selected");
        // if (sourceSelect)
        // sourceSelect.setData({
        //         type: "FeatureCollection",
        //         features: [],
        //     });

        var sourceSelect = map.getSource("ready-cells-owner");
        if (sourceSelect)
          sourceSelect.setData({
            type: "FeatureCollection",
            features: [],
          });

        var sourceLine = map.getSource("ready-cells-selectedLine");
        if (sourceLine)
          sourceLine.setData({
            type: "FeatureCollection",
            features: [],
          });
        return;
      }
      var mapInfo = {
        lat: map.getCenter().lat,
        lng: map.getCenter().lng,
        distance: this.getDistance(),
      };
      var idLogin = null;
      if (localStorage.getItem("user")) {
        var userLogin = JSON.parse(localStorage.getItem("user"));
        idLogin = userLogin.id;
      }

      axios
        .get("/api/lands", { params: mapInfo })
        .then((response) => {
          let data = response.data;

          readyItems = [];
          var collections = [];
          readyItemsOwner = [];
          var aUnionCoordinates = [];
          data.items.forEach((item) => {
            let bbox = item.location.bbox;
            if (bbox) {
              let coordinates = item.location.coordinates;
              const cell = {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  bbox,
                  coordinates,
                },
              };

              readyItems.push(cell);
              if (idLogin == item.owner) {
                const cellIndex = readyItemsSelected.findIndex(
                  (x) => x.geometry.bbox.toString() === bbox.toString()
                );
                if (cellIndex === -1) {
                  readyItemsOwner.push(cell);
                }
              }

              if (item.collection_parent != "") {
                if (
                  collections["" + item.collection_parent + ""] == undefined
                ) {
                  collections["" + item.collection_parent + ""] = [item];
                } else {
                  collections["" + item.collection_parent + ""].push(item);
                }
              } else {
                aUnionCoordinates.push({
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    bbox,
                    coordinates,
                  },
                });
              }
            }
          });
          //console.log(collections, "collections");
          for (var key in collections) {
            //console.log("collections #" + key); // "User john is #234"
            var isCheck = collectionsAll.includes(key);
            if (!isCheck) {
              collectionsAll.push(key);
            }

            var readyItemsSelectedLine = [];
            var union = undefined;

            collections["" + key + ""].forEach(function (item2) {
              let coordinates = item2.location.coordinates;
              readyItemsSelectedLine.push({
                type: "Feature",
                properties: {
                  fill: "#0f0",
                },
                geometry: {
                  type: "Polygon",
                  coordinates: coordinates,
                },
              });
            });
            for (var i = 0; i < readyItemsSelectedLine.length; i++) {
              if (i == 0) {
                continue;
              }
              if (i == 1) {
                union = turf.union(
                  readyItemsSelectedLine[0],
                  readyItemsSelectedLine[1]
                );
              } else {
                union = turf.union(union, readyItemsSelectedLine[i]);
              }
            }
            if (union == undefined) {
              union = readyItemsSelectedLine[0];
            }
            aUnionCoordinates.push({
              type: "Feature",
              properties: {
                fill: "#0f0",
              },
              geometry: {
                type: union.geometry.type,
                coordinates: union.geometry.coordinates,
              },
            });
          }
          if (aUnionCoordinates.length > 0) {
            if (!map.getSource("ready-cells-selectedLine")) {
              map.addSource("ready-cells-selectedLine", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  properties: {},
                  features: aUnionCoordinates,
                },
              });

              map.addLayer({
                id: "ready-cells-selectedLine",
                source: "ready-cells-selectedLine",
                type: "line",
                paint: {
                  "line-color": "#E91E63",
                  "line-width": 2,
                  "line-opacity": 0.6,
                },
              });
            } else {
              const sourceLine = map.getSource("ready-cells-selectedLine");
              sourceLine.setData({
                type: "FeatureCollection",
                properties: {},
                features: aUnionCoordinates,
              });
            }
          }

          const source = map.getSource("ready-cells");
          source.setData({
            type: "FeatureCollection",
            features: readyItems,
          });

          const sourceOwner = map.getSource("ready-cells-owner");
          sourceOwner.setData({
            type: "FeatureCollection",
            features: readyItemsOwner,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getBBox() {
      if (!map) {
        throw new Error("Invalid state");
      }

      const bounds = map.getBounds();
      if (bounds.getEast() - bounds.getWest() >= 360) {
        bounds.setNorthEast([bounds.getWest() + 360, bounds.getNorth()]);
      }

      const bbox = /** @type {GeoJSON.BBox} */ (bounds.toArray().flat());
      return bbox;
    },
    updateBound() {
      //let g = getGrid();
      console.log("On drag");
    },

    updatePricing() {
      let selectedItems = selectedCells;

      this.selectedLand = {
        price: 25 * selectedItems.length,
        items: selectedCells,
      };

      //get price
      this.getPrice();
    },

    addToCart() {
      if (this.user && this.user.wl == 1) {
        this.$store.dispatch("cart/addItems", {
          items: selectedCells,
        });
        window.scrollTo(0, 0);
      } else {
        this.$store.dispatch("landCreditTopup/showLandSaleDialog", {});
      }
      /*
                  // Show landsale dialog
                  this.$store.dispatch("landCreditTopup/showLandSaleDialog", {
                  });*/
    },

    getLocation(sLocation) {
      var aLocation = sLocation.split(",");
      return aLocation[1] + ", " + aLocation[0];
    },

    clearSelectionLand() {
      this.selectedLand = undefined;
      selectedCells = [];
      const selectedCellsId = "selected-cells";
      const source = map.getSource(selectedCellsId);
      source.setData({
        type: "FeatureCollection",
        features: selectedCells,
      });
    },

    getPrice() {
      let self = this;
      axios
        .post(`/api/getprice`, {
          items: self.selectedLand.items.map((e) => {
            return {
              location: e.geometry.coordinates,
              bbox: e.geometry.bbox,
            };
          }),
        })
        .then((response) => {
          if (response.data && !response.data.error) {
            self.data = response.data.data;
          } else {
            this.$swal(response.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    onMapViewStyle(style) {
      let self = this;
      switch (style) {
        case "streets-v11":
          this.btnMapView = "Map";
          break;
        case "satellite-streets-v12":
          this.btnMapView = "Satellite";
          break;
        default:
          this.btnMapView = "Map";
      }
      var center = map.getCenter();
      //   const source = map.getSource("selected-cells");
      //   console.log(source, "source 1");
      //   map.setStyle("mapbox://styles/mapbox/" + style);
      //   const source2 = map.getSource("selected-cells");
      //   console.log(source2, "source 2");
      self.initMap(style, {
        lat: center.lat,
        lng: center.lng,
        zoom: map.getZoom(),
      });
      this.satelliteIsOpen = !this.satelliteIsOpen;
    },

    onSearch() {
      let self = this;

      clearTimeout(timer);
      timer = setTimeout(function () {
        axios
          .get(
            "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
              self.filter.search +
              ".json?proximity=103.7302585,1.4262305&access_token=pk.eyJ1IjoiY29kZWNhbG0iLCJhIjoiSzRiOVJvQSJ9.BUVkTT6IYs83xSUs4H7bjQ"
          )
          .then((response) => {
            let data = response.data;
            console.log(data);
          })
          .catch((e) => {
            console.log(e);
          });
      }, 200);
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    async sendETH(amount) {
      let web3 = new Web3(window.ethereum);
      var accounts = await web3.eth.getAccounts();
      var account = accounts[0];

      //on loading
      this.$store.dispatch("map/openLoading", { status: true });

      await web3.eth
        .sendTransaction({
          from: account,
          to: process.env.VUE_APP_CRYPTO_WALLET_ADDRESS,
          value: web3.utils.toWei(amount.toString(), "ether"),
        })
        .then((result) => {
          this.contractResult = result;
          console.log("contractResult", this.contractResult);

          //update status
          this.doUpdatePaymentStatus();
        })
        .catch((e) => {
          console.log("Error", e);
          this.$swal(e.message, "", "error");
          this.$store.dispatch("map/openLoading", { status: false });
        });
    },

    doBuyLandWithETH() {
      this.$store.dispatch("cart/ethCryptoPayment");
    },

    doUpdatePaymentStatus() {
      axios
        .post(`/api/lands/crypto_payment_status`, {
          transaction_id: localStorage.getItem("transaction_crypto_id"),
          transactionHash: this.contractResult.transactionHash,
        })
        .then((response) => {
          if (response.data && !response.data.error) {
            console.log(response.data);

            // //off loading
            this.$store.dispatch("map/openLoading", { status: false });

            // //open modal transaction info
            this.modal_transaction = true;

            this.clearSelectionLand();
            this.$store.dispatch("cart/cleanItemsAll");
            this.$store.dispatch("cart/openCart", {
              status: false,
            });

            this.closeCheckout();

            this.fetchLands();
          } else {
            this.$swal(response.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    /** Get information of area by lat lng address */
    getArea(latlng) {
      let self = this;
      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
            latlng +
            `.json?access_token=` +
            this.access_token_mapbox,
          {}
        )
        .then((r) => {
          let data = r.data;
          console.log(data, "Mapbox info");
          let count = data.features.length;
          let district = data.features[count - 2];
          let country = data.features[count - 1];

          let areas = {
            district: district ? district.place_name : "",
            country: country ? country.place_name : "",
          };

          console.log(areas, "areas");

          if (areas) {
            selectedCells[selectedCells.length - 1].areas = areas;

            self.selectedLand = {
              price: 25 * selectedCells.length,
              items: selectedCells,
            };
            Object.assign(self.$data.selectedLand, self.selectedLand);
          }

          // if (r.data.error) {
          //     this.$swal(r.data.msg);
          // } else {
          //     this.data = r.data;
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (p) => {
            if (map) {
              map.setZoom(13);
              map.flyTo({
                center: [p.coords.longitude, p.coords.latitude],
              });
            }
          },
          (e) => {
            //map.setZoom(3)
            console.log(e);
          },
          { timeout: 5000 }
        );
      } else {
        //Geolocation is not supported by this browser
        console.log("Can not get location");
        //map.setZoom(3)
      }
    },
    isLatitude(lat) {
      return isFinite(lat) && Math.abs(lat) <= 90;
    },
    isLongitude(lng) {
      return isFinite(lng) && Math.abs(lng) <= 180;
    },
    getDistance() {
      const bounds = map.getBounds();
      const topLeft = point([bounds._ne.lng, bounds._ne.lat]);
      const topRight = point([bounds._sw.lng, bounds._ne.lat]);
      const bottomLeft = point([bounds._ne.lng, bounds._sw.lat]);
      const bottomRight = point([bounds._sw.lng, bounds._sw.lat]);

      const middleLeft = midpoint(topLeft, bottomLeft);
      const middleRight = midpoint(topRight, bottomRight);
      const distanceRessult = distance(middleLeft, middleRight, "kilometers");
      return distanceRessult / 2;
    },
    initMap: function (style, config) {
      let self = this;
      // @formatter:off
      // eslint-disable-next-line no-undef
      mapboxgl.accessToken = self.access_token_mapbox;

      if (config == undefined) {
        // eslint-disable-next-line no-unused-vars, no-undef
        map = new mapboxgl.Map({
          container: "map-simple",
          style: "mapbox://styles/mapbox/" + style,
          zoom: 1.5,
          center: [103.7302585, 25.4262305],
        });
      } else {
        // eslint-disable-next-line no-unused-vars, no-undef
        map = new mapboxgl.Map({
          container: "map-simple",
          style: "mapbox://styles/mapbox/" + style,
          zoom: config.zoom,
          center: [config.lng, config.lat],
        });
      }

      map.addControl(
        // eslint-disable-next-line no-unused-vars, no-undef
        new mapboxgl.NavigationControl({
          showCompass: true,
          showZoom: true,
        }),
        "bottom-right"
      );
      // eslint-disable-next-line no-undef
      const geocoder = new MapboxGeocoder({
        // Initialize the geocoder
        // eslint-disable-next-line no-undef
        accessToken: mapboxgl.accessToken, // Set the access token
        // eslint-disable-next-line no-undef
        mapboxgl: mapboxgl, // Set the mapbox-gl instance
        marker: false, // Do not use the default marker style
      });
      var colorGird = "#0000ff";
      var lineopacity = 0.2;
      var colorGirdSelected = "#4f9fff";
      var fillColorActive = "#000000";
      if (style == "satellite-streets-v12") {
        colorGird = "#4f9fff";
        lineopacity = 0.8;
        colorGirdSelected = "#4f9fff";
        fillColorActive = "#ffffff";
      }
      map.addControl(geocoder);
      const grid1 = new Grid({
        gridWidth: 0.4,
        gridHeight: 0.4,
        minZoom: 11,
        maxZoom: 19,
        units: "kilometers",
        paint: {
          "line-opacity": lineopacity,
          "line-color": colorGird,
        },
      });
      map.addControl(grid1);

      map.on("moveend", () => {
        this.fetchLands();
      });
      map["boxZoom"].enable();

      map.on("load", async () => {
        const selectedCellsId = "selected-cells";

        self.fetchLands();

        map.addSource(selectedCellsId, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: selectedCells,
          },
        });

        map.addLayer({
          id: selectedCellsId,
          source: selectedCellsId,
          type: "fill",
          paint: {
            "fill-color": colorGirdSelected,
            "fill-opacity": 0.4,
          },
        });

        /*
                    // Load an image to use as the pattern from an external URL.
                    map.loadImage("https://docs.mapbox.com/mapbox-gl-js/assets/colorado_flag.png", (err, image) => {
                        // Throw an error if something goes wrong.
                        if (err) throw err;
        
                        // Add the image to the map style.
                        map.addImage("pattern", image);
        
                    });*/

        map.addSource("ready-cells", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.addLayer({
          id: "ready-cells",
          source: "ready-cells",
          type: "fill",
          paint: {
            "fill-color": fillColorActive,
            "fill-opacity": 0.4,
            "fill-outline-color": "#FFFFFF",
          },
        });

        map.addSource("ready-cells-owner", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.addLayer({
          id: "ready-cells-owner",
          source: "ready-cells-owner",
          type: "fill",
          paint: {
            "fill-color": "#0000ff",
            "fill-opacity": 0.4,
          },
        });

        map.addSource("ready-cells-selected", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [],
          },
        });

        map.addLayer({
          id: "ready-cells-selected",
          source: "ready-cells-selected",
          type: "fill",
          paint: {
            "fill-color": "#ffff00",
            "fill-opacity": 0.4,
          },
        });
        //Search
        const collection = document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        );

        // Execute a function when the user presses a key on the keyboard
        if (collection.length > 0) {
          const input = collection[0];
          input.addEventListener("keypress", function (event) {
            // If the user presses the "Enter" key on the keyboard
            if (event.key === "Enter") {
              // Cancel the default action, if needed
              event.preventDefault();
              // Trigger the button element with a click
              var valueLatLng = input.value;
              var a_valueLatLng = valueLatLng.split(",");
              if (a_valueLatLng.length < 2) {
                return;
              }
              if (!self.isLatitude(a_valueLatLng[0])) {
                return;
              }

              if (!self.isLongitude(a_valueLatLng[1])) {
                return;
              }
              map.setZoom(13);
              map.flyTo({
                center: [a_valueLatLng[1], a_valueLatLng[0]],
              });
            }
          });
        }

        map.on(GRID_SELECT_EVENT, (event) => {
          console.log(event);
          //if (!isShiftButton) {
          //    return
          //}
          const bbox = event.bbox;

          function getCenterWithRound(bbox) {
            var center = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];
            return [center[0].toFixed(11), center[1].toFixed(11)].join(",");
          }

          const found = readyItems.findIndex(
            (x) =>
              getCenterWithRound(x.geometry.bbox) === getCenterWithRound(bbox)
          );
          if (found !== -1) {
            return;
          }
          const cellIndex = selectedCells.findIndex(
            (x) => x.geometry.bbox.toString() === bbox.toString()
          );
          if (cellIndex === -1) {
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ];
            const cell = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                bbox,
                coordinates,
              },
              areas: undefined,
            };
            // console.log("Select land item", cell);
            if (selectedCells.length < 300) {
              selectedCells.push(cell);
            }
            self.getArea(coordinates[0][0].toString());
          }
          const source = map.getSource(selectedCellsId);
          source.setData({
            type: "FeatureCollection",
            features: selectedCells,
          });
          //self.updatePricing();
        });

        map.on(GRID_CLICK_EVENT, (event) => {
          if (isShiftButton) {
            logClickPoint.push(event.bbox);
            console.log(logClickPoint);
            return;
          }

          const bbox = event.bbox;
          // axios
          //   .get(
          //     "/api/game/lands?lat=" +
          //       event.point[1] +
          //       "&lng=" +
          //       event.point[0],
          //     {}
          //   )
          //   .then((rmap) => {
          //     let data = rmap.data;
          //
          //     for (var j = 0; j < data.items.length; j++) {
          //       for (var i = 0; i < data.items[j].coordinates[0].length; i++) {
          //         var color = "black";
          //         if (i == 0) {
          //           color = "black";
          //         }
          //         if (i == 1) {
          //           color = "blue";
          //         }
          //         if (i == 2) {
          //           color = "red";
          //         }
          //         if (i == 3) {
          //           color = "yellow";
          //         }
          //         if (i == 4) {
          //           continue;
          //         }
          //         console.log(data.items[j].coordinates[0][i], "Point");
          //         // eslint-disable-next-line no-undef
          //         new mapboxgl.Marker({ color: color, rotation: 0 })
          //           .setLngLat(data.items[j].coordinates[0][i])
          //           .addTo(map);
          //       }
          //     }
          //   });

          function getCenterWithRound(bbox) {
            var center = [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2];
            return [center[0].toFixed(11), center[1].toFixed(11)].join(",");
          }

          const found = readyItems.findIndex(
            (x) =>
              getCenterWithRound(x.geometry.bbox) === getCenterWithRound(bbox)
          );

          readyItemsOwner = readyItemsOwner.concat(readyItemsOwnerRemove);
          readyItemsOwnerRemove = [];
          const sourceOwnerSet = map.getSource("ready-cells-owner");
          sourceOwnerSet.setData({
            type: "FeatureCollection",
            features: readyItemsOwner,
          });
          // This is error
          // const found = readyItems.findIndex(x => x.geometry.bbox.toString() === bbox.toString());
          if (found !== -1) {
            // Request to backend and get owner information
            axios
              .post("/api/land/info", {
                bbox,
              })
              .then((r) => {
                if (!r.data.error) {
                  let latlng = getCenterWithRound(bbox);

                  axios
                    .get(
                      `https://api.mapbox.com/geocoding/v5/mapbox.places/` +
                        latlng +
                        `.json?access_token=` +
                        this.access_token_mapbox,
                      {}
                    )
                    .then((rmap) => {
                      let data = rmap.data;
                      console.log(data, "Mapbox info");
                      let count = data.features.length;
                      var address = "";
                      if (r.data.user.address == "") {
                        let district = data.features[count - 2];
                        let country = data.features[count - 1];
                        address =
                          district.place_name + " " + country.place_name;
                      } else {
                        address = r.data.user.address;
                      }

                      self.ownerLandInfo = {
                        location: latlng,
                        address: address,
                        ownerName: r.data.user.name,
                        ownerAvatar: r.data.user.avatar,
                        lands: r.data.user.lands,
                        isMinted: r.data.user.isMinted,
                        uriid: r.data.user.uriid,
                      };

                      readyItemsSelected = [];
                      r.data.user.lands.forEach((item) => {
                        let bbox = item.locations.bbox;
                        //readyItemsOwnerRemove.forEach
                        var checkRemove = false;
                        for (var i = 0; i < readyItemsOwner.length; i++) {
                          if (
                            JSON.stringify(bbox) ==
                            JSON.stringify(readyItemsOwner[i].geometry.bbox)
                          ) {
                            readyItemsOwnerRemove.push(readyItemsOwner[i]);
                            readyItemsOwner.splice(i, 1);
                            checkRemove = true;
                          }
                        }
                        if (checkRemove) {
                          const sourceOwner =
                            map.getSource("ready-cells-owner");
                          sourceOwner.setData({
                            type: "FeatureCollection",
                            features: readyItemsOwner,
                          });
                        }

                        if (bbox) {
                          let coordinates = item.locations.coordinates;
                          const cell = {
                            type: "Feature",
                            geometry: {
                              type: "Polygon",
                              bbox,
                              coordinates,
                            },
                          };
                          //const cellIndex = readyItems.findIndex(x => x.geometry.bbox.toString() === bbox.toString());
                          //if (cellIndex === -1)
                          readyItemsSelected.push(cell);
                        }
                      });
                      const source = map.getSource("ready-cells-selected");
                      source.setData({
                        type: "FeatureCollection",
                        features: readyItemsSelected,
                      });
                    });
                } else {
                  this.$swal(
                    "You cannot select this land plot. It belongs to another user.",
                    "",
                    "error"
                  );
                }
              })
              .catch(() => {
                this.$swal(
                  "You cannot select this land plot. It belongs to another user.",
                  "",
                  "error"
                );
              });
            return;
          } else {
            self.ownerLandInfo = undefined;
            const source = map.getSource("ready-cells-selected");
            source.setData({
              type: "FeatureCollection",
              features: [],
            });
          }

          const cellIndex = selectedCells.findIndex(
            (x) => x.geometry.bbox.toString() === bbox.toString()
          );
          if (cellIndex === -1) {
            const coordinates = [
              [
                [bbox[0], bbox[1]],
                [bbox[2], bbox[1]],
                [bbox[2], bbox[3]],
                [bbox[0], bbox[3]],
                [bbox[0], bbox[1]],
              ],
            ];
            const cell = {
              type: "Feature",
              geometry: {
                type: "Polygon",
                bbox,
                coordinates,
              },
              areas: undefined,
            };
            // console.log("Select land item", cell);
            if (selectedCells.length >= 300) {
              this.$swal(
                "You can select a maximum of 300 land plots per transaction. Please checkout with your current selection and you can continue shopping after.",
                "",
                "error"
              );
            } else {
              if (found === -1) {
                selectedCells.push(cell);
              }
            }
            self.getArea(coordinates[0][0].toString());
          } else {
            selectedCells.splice(cellIndex, 1);
          }

          if (found === -1) {
            const source = map.getSource(selectedCellsId);
            source.setData({
              type: "FeatureCollection",
              features: selectedCells,
            });
            self.updatePricing();
          }
        });
      });
    },
  },

  computed: {
    ...mapState("account", ["status", "creditAmount", "user"]),
    ...mapState("cart", [
      "totalItems",
      "itemsOnCart",
      "totalPrice",
      "itemsPrice",
      "checkoutStatus",
      "landType",
      "landCollection",
      "landPlotBuyResult",
      "addCartException",
      "showDialogPaymentStripe",
    ]),
    ...mapState("map", ["styleMap"]),
  },
  watch: {
    landPlotBuyResult: function ({ isError }) {
      this.clearSelectionLand();
      if (!isError) {
        this.fetchLands();
      }
    },
    showDialogPaymentStripe: async function () {
      var result = await this.$swal.fire({
        title: "Payment unsuccessful. Please try again.",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
        denyButtonText: `Cancel`,
      });
      if (result.isConfirmed) {
        this.$store.dispatch("cart/terminateCartWertIOPayment");
        this.$store.dispatch("map/openLoading", { status: true });
        var responseData = await this.$store.dispatch(
          "cart/creditPaymentStripe",
          {
            type: 2,
            collection: [],
            items: [],
          }
        );
        this.$store.dispatch("map/openLoading", { status: false });
        console.log(responseData, "responseData Stript");
        if (responseData.error) {
          this.$swal(responseData.msg, "Sorry", "error");
        } else {
          document.location.href = responseData.data;
        }
      } else {
        this.$store.dispatch("cart/hideDialogPaymentStripe");
      }
    },
    addCartException: function ({ error, title, msg }) {
      this.$swal(title, msg, error ? "error" : false);
    },
  },

  // `mounted` is a lifecycle hook which we will explain later
  mounted() {
    let self = this;
    setTimeout(() => {
      self.getCurrentLocation();
    }, 2000);
    self.initMap("streets-v12");
  },
};
</script>
