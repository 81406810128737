/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
//import Web3 from "web3";
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'
import { BrowserProvider, ethers } from 'ethers'

//var gTransactionHash = ""

const state = {
    mintingStep: 0,
    error: null,
    isMinting: false,
    web3Modal: undefined,
    paymentTransactionData: null,
    timer: null,
    watching: [],
    status: [],
}


const actions = {


    async getStatus({ state }) {
        try {
            if (state.watching.length == 0) {
                if (state.timer) {
                    state.isMinting = false;
                    clearInterval(state.timer);
                }
            }
            let r = await axios.post(`/api/minting/refresh`, {
                collections: state.watching,
            });
            if (r.data) {
                console.log(r.data.states);
                state.status = r.data.states;
                if (r.data.states.length != 0) {
                    r.data.states.forEach(element => {
                        // Neu khong phai la 1 hoac 3
                        if (![1, 3].includes(element.state)) {
                            // remove from array
                            state.watching = state.watching.filter(e => {
                                return e != element.id;
                            })
                        }
                    });
                }
            }
        } catch (error) {
            console.log(error);
        }
    },

    async runTimer({ state, dispatch }, { collectionID }) {
        if (!state.watching.includes(collectionID)) {
            state.watching.push(collectionID);
        }
        if (!state.timer) {
            state.timer = setInterval(() => {
                dispatch('getStatus')
            }, 2000);
        }
    },



    async doPayment({ state, commit, dispatch }) {
        let paymentTransactionData = state.paymentTransactionData; // , callback, onReject
        try {
            commit("UITopupPaymentSuccess", {
                msg: {
                    msg: "DO NOT close or refresh the page. Please sign on your wallet.",
                    title: 'Minting request in progress...',
                    hide: true
                }
            })
            const walletProvider = state.web3Modal.getWalletProvider();
            const ethersProvider = new BrowserProvider(walletProvider)
            const signer = await ethersProvider.getSigner()
            const tx = await signer.sendTransaction({
                to: paymentTransactionData.targetWallet,
                value: ethers.parseEther(paymentTransactionData.amountCurrency + "")
            });
            if (tx.hash) {
                let verifyResponse = await axios.post('/api/minting/payment_update', {
                    tid: paymentTransactionData.tid,
                    txh: tx.hash
                })
                if (!verifyResponse.data.error) {
                    //callback(tx.hash);
                    dispatch('runTimer', { collectionID: paymentTransactionData.collectionID });
                    commit("UITopupPaymentSuccess", {
                        msg: {
                            msg: "Minting in progress.",
                            title: 'Your NFT is currently being minted. We will notify you via email as soon as the minting process is successfully completed. Feel free to close this window if you wish. Transaction Hash:' + tx.hash
                        }
                    })
                    //state.isMinting = false;
                } else {
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: "Unable to sign transaction.",
                            title: 'Please try again.'
                        }
                    })
                    state.minting = false;
                }
            }
        } catch (error) {
            state.isMinting = false;
            console.log('r', error);
            //if (error.reason == 'rejected') {
            commit("UITopupPaymentError", {
                msg: {
                    msg: error.message,
                    title: 'Please try again.'
                }
            })
            //}
        }
    },



    /**
     * Make request for minting nft from collection id
     */
    async request({
        commit,
        state,
        dispatch
    }, {
        collectionID,
        onReject,
        callback
    }) {

        if (state.isMinting) {
            commit("UITopupPaymentError", {
                msg: {
                    msg: "The minting process is currently underway, please wait until it completes and try again later.",
                    title: 'Please wait !!'
                }
            })
            return;
        }
        const projectId = 'e2b9e7321ebfbb20cfc42acbf8932610'

        let paymentTransactionData = null;
        try {
            let response = await axios.post('/api/minting/request/', {
                collectionID
            });
            paymentTransactionData = response.data;
            state.paymentTransactionData = paymentTransactionData;
            if (paymentTransactionData.error) {
                onReject();
                commit("UITopupPaymentError", {
                    msg: {
                        msg: paymentTransactionData.msg,
                        title: ' '
                    }
                })
                return;
            } else {
                if (paymentTransactionData.already) {
                    callback(' ');
                    commit("UITopupPaymentSuccess", {
                        msg: {
                            msg: "Congratulations   ",
                            title: 'Your NFT mint request successfully.'
                        }
                    })
                    return;
                }
            }
        } catch (error) {
            onReject();
            commit("UITopupPaymentError", {
                msg: {
                    msg: error,
                    title: 'Payment request  error !!'
                }
            })
            return
        }

        //console.log(paymentTransactionData);

        if (!state.web3Modal) {

            // 2. Set chains
            const mainnet = {
                chainId: parseInt(paymentTransactionData.targetNetworkId),
                name: 'Ethereum Mainnet',
                currency: 'ETH',
                explorerUrl: 'https://etherscan.io',
                rpcUrl: 'https://cloudflare-eth.com'
            }

            // 3. Create modal
            const metadata = {
                name: 'Gate Of Abyss',
                description: 'Gate of Abyss - Location-Based RPG Mobile Game',
                url: 'https://app.gateofabyss.com',
                icons: ['https://app.gateofabyss.com/favicon.ico']
            }

            state.web3Modal = createWeb3Modal({
                ethersConfig: defaultConfig({ metadata }),
                chains: [mainnet],
                projectId,
                includeWalletIds: [
                    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                    '0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150',
                    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0'
                ]
            });

            state.isMinting = true;

            let modal = state.web3Modal;
            modal.subscribeEvents(async event => {
                console.log('Wallet Event receiver', event.data);
                if (event.data.event == 'CONNECT_SUCCESS') {
                    const chainId = modal.getChainId()
                    console.log("Detect chain ID", chainId);
                    if (chainId != paymentTransactionData.targetNetworkId) {
                        await modal.close();
                        setTimeout(async () => {
                            modal.open({
                                view: 'Networks'
                            })
                        }, 1000);
                    } else {
                        dispatch('doPayment');
                    }
                }
                if (event.data.event == 'MODAL_CLOSE') {
                    if (modal.getChainId() == paymentTransactionData.targetNetworkId && modal.getAddress() && modal.getIsConnected()) {
                        dispatch('doPayment');
                        //doPayment(paymentTransactionData, callback, onReject); 
                    }/* else {
                        state.isMinting = false;
                        onReject();
                        commit("UITopupPaymentError", {
                            msg: {
                                msg: "You have declined the transaction, please try again.",
                                title: 'Payment request  error !!'
                            }
                        })
                    }*/
                }
            })
        }

        let modal = state.web3Modal;


        //await modal.disconnect();
        await modal.open({
            view: 'Connect'
        });


        return;
        //const address = modal.getAddress();
        //alert(address);

        /*
        if (!modal.getWalletProvider()) {
            console.log("chuwa co prvd");
            // Neu chua mo modal
            modal.open({
                view: 'Networks'
            }).then(() => {
                console.log('slt');
                // Da mo xong
                //  doPayment();
            })
        } else {
            //doPayment();

        }* /

        const isConnected = modal.getIsConnected();
        if (!isConnected) {
            modal.open({
                view: 'Connect'
            });
        }
        const walletProvider = modal.getWalletProvider();
        const chainId = modal.getChainId()

        console.log({ isConnected, walletProvider, chainId })



        // eslint-disable-next-line no-unused-vars
        let doPayment = async function () {
            const walletProvider = modal.getWalletProvider();
            const ethersProvider = new BrowserProvider(walletProvider)
            /* await ethers.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: paymentTransactionData.targetNetworkId
                }],
            }) * /
            const signer = await ethersProvider.getSigner()
            // The Contract object
            console.log(await signer.getAddress());

            const tx = await signer.sendTransaction({
                to: paymentTransactionData.targetWallet,
                value: ethers.parseEther(paymentTransactionData.amountCurrency + "")
            });
            console.log(tx);
        } */

        //if (!walletProvider) {

        //}
        /*
        */

        //const USDTContract = new Contract(USDTAddress, USDTAbi, signer)
        //const USDTBalance = await USDTContract.balanceOf(address)


        /*
        if (state.isMinting) {
            return;
        }

        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/minting/request/', {
                collectionID
            });
            paymentTransactionData = response.data;
            if (paymentTransactionData.error) {
                commit("UITopupPaymentError", {
                    msg: {
                        msg: paymentTransactionData.msg,
                        title: ' '
                    }
                })
                return;
            } else {
                if (paymentTransactionData.already) {
                    callback(' ');
                    commit("UITopupPaymentSuccess", {
                        msg: {
                            msg: "Congratulations   ",
                            title: 'Your NFT mint request successfully.'
                        }
                    })
                    return;
                }
            }
        } catch (error) {
            commit("UITopupPaymentError", {
                msg: {
                    msg: error.response.data.msg,
                    title: 'Payment request  error !!'
                }
            })
            return
        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;
        var countRequestVerify = 0

        if (amountCurrency == 0) {
            axios.post('/api/minting/request/verify_payment', {
                tid: transactionID,
                txh: ''
            }).then((response) => {
                if (response.data) {
                    if (response.data.error) {
                        commit("UITopupPaymentError", {
                            msg: {
                                msg: response.data.msg,
                                title: ' '
                            }
                        })
                    } else {
                        callback(' ');
                        commit("UITopupPaymentSuccess", {
                            msg: {
                                msg: "Congratulations   ",
                                title: 'Your NFT mint request successfully.'
                            }
                        })
                    }
                } else {
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: 'Unable request to server, please try again.',
                            title: ' '
                        }
                    })
                }
            }).catch((e) => {
                // Can not validate transaction ??? 
                console.log(e);
                commit("UITopupPaymentError", {
                    msg: {
                        msg: e.response.data.msg,
                        title: ' '
                    }
                })
            })
            return;
        }





        //this.$swal("Please login to access", "Require login", "error");
        if (typeof window.ethereum === "undefined") {
            return commit("UITopupPaymentError", {
                msg: {
                    msg: "Please install Metamask and connect your wallet",
                    title: ' '
                }
            })
        }

        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{
                    chainId: targetNetworkId
                }],
            });
        } catch (err2) {
            console.log(err2);
        }
        state.isMinting = true;


        // Request connect eth network
        try {
            console.log("MINTING", "Open request dialog");

            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            /*const instance = new Web3(window.ethereum);
            // Get necessary info on your node
            
            var networkId = await instance.eth.net.getId();
            var coinbase = await instance.eth.getCoinbase();
            var balance = await instance.eth.getBalance(coinbase);
            this.networkId = networkId;
            this.address = coinbase;
            this.balance = balance;
            //console.log("initWeb3 networkId: ", networkId);
            //console.log("coinbase networkId: ", coinbase);
            //console.log("balance networkId: ", balance);
            * /


        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);
            onReject();

            axios.patch('/api/minting/request/status', {
                tid: transactionID,
                status: 4,
                payload: {
                    "msg": "User denied web3 access",
                    error: error.message
                }
            })

            return commit("UITopupPaymentError", {
                msg: {
                    msg: "User denied web3 access",
                    title: 'Please install Metamask and connect your wallet '
                }
            })
        }

        // amount

        let verifyCallback = function (transactionHash) {
            countRequestVerify++
            axios.post('/api/minting/request/verify_payment', {
                tid: transactionID,
                txh: transactionHash
            }).then((response) => {
                if (response.data) {
                    if (response.data.error) {
                        console.log(countRequestVerify,"AAAAAA")
                        if(countRequestVerify > 10){
                            countRequestVerify = 0;
                            commit("UITopupPaymentError", {
                                msg: {
                                    msg: response.data.msg,
                                    title: ' '
                                }
                            })
                        }else{
                            setTimeout(function(){
                                verifyCallback(transactionHash)
                            },10000)
                        }
                    } else {
                        callback(transactionHash);
                        countRequestVerify = ""
                        commit("UITopupPaymentSuccess", {
                            msg: {
                                msg: "Congratulations   ",
                                title: 'Your NFT has been minted successfully.'
                            }
                        })
                    }
                } else {
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: 'Unable request to server, please try again.',
                            title: ' '
                        }
                    })
                }
            }).catch(() => {
                // Can not validate transaction ??? 
                if(countRequestVerify > 10){
                    countRequestVerify = 0;
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: "Cannot validate transaction. Please contact us!",
                            title: 'Transaction Hash: ' + transactionHash
                        }
                    })
                }else{
                    setTimeout(function(){
                        verifyCallback(transactionHash)
                    },10000)
                }

            })
        }

        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];

                commit("UITopupPaymentSuccess", {
                    msg: {
                        msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                        title: 'Minting in progress...',
                        hide: true
                    }
                })

                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 1,
                    receiver: account,
                    payload: {}
                })

                console.log({
                    from: account,
                    to: targetWallet,
                    value: web3.utils.toWei(amount.toString(), "ether"),
                });

                const gasPrice = await web3.eth.getGasPrice()

                web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                        gasPrice: gasPrice
                    }).on('transactionHash', function (hash) {
                        // alert(hash + " transactionHash")
                        console.log(hash, "transactionHash")
                        gTransactionHash = hash
                        verifyCallback(gTransactionHash)
                    })
                    .on('receipt', function (receipt) {
                        // alert(receipt + " receipt")
                        console.log(receipt, "receipt")
                        // callback(receipt.transactionHash)
                        // gTransactionHash = ""
                    })
                    .on('confirmation', function (confirmationNumber, receipt) {
                        // alert(confirmationNumber + " confirmation")
                        // alert(receipt +" confirmation")
                        console.log(confirmationNumber, "confirmationNumber")
                        console.log(receipt, "confirmationNumber receipt")
                    })
                    .on('error', function (error) {
                        if (gTransactionHash == "") {
                            state.isMinting = false;
                            onReject();
                            axios.patch('/api/minting/request/status', {
                                tid: transactionID,
                                status: 4,
                                payload: {
                                    "msg": "User denied web3 access",
                                    error: error.message,
                                }
                            }).then(() => {
                                commit("UITopupPaymentError", {
                                    msg: {
                                        msg: error.message,
                                        title: 'Transaction error'
                                    }
                                })
                            }).catch(() => {
                                commit("UITopupPaymentError", {
                                    msg: {
                                        msg: error.message,
                                        title: 'Transaction error'
                                    }
                                })
                            })
                        }
                    });

                // .then(function (result) {
                //     // your code here
                //     callback(result)
                //     console.log("ETH Transfer result", result)
                //     state.isMinting = false;
                // });

                //dispatch("map/openLoading", { status: false });
                // if (result) {
                //     console.log('ETH Transfer result', result)
                //     return result;
                // }
            } catch (error) {
                state.isMinting = false;
                onReject();
                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 4,
                    payload: {
                        "msg": "User denied web3 access",
                        error: error.message + " transactionHash: " + gTransactionHash,
                    }
                }).then(() => {
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: error.message + " transactionHash: " + gTransactionHash,
                            title: 'Transaction error'
                        }
                    })
                }).catch(() => {
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: error.message,
                            title: 'Transaction error'
                        }
                    })
                })
            }
            return
        }
        // After send eth
        sendETH(amountCurrency, function (result) {
            if (result) {
                commit("UITopupPaymentSuccess", {
                    msg: {
                        msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                        title: 'Minting initiated. Validating your transaction.',
                        hide: true
                    }
                })

                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 2,
                    payload: result
                })


                // Verify and go to minting step /api/credit/payment/eth_crypto/verify

                axios.post('/api/minting/request/verify_payment', {
                    tid: transactionID,
                    txh: result
                }).then((response) => {
                    if (response.data) {
                        if (response.data.error) {
                            commit("UITopupPaymentError", {
                                msg: {
                                    msg: response.data.msg,
                                    title: ' '
                                }
                            })
                        } else {
                            callback(result);
                            commit("UITopupPaymentSuccess", {
                                msg: {
                                    msg: "Congratulations   ",
                                    title: 'Your NFT has been minted successfully.'
                                }
                            })
                        }
                    } else {
                        commit("UITopupPaymentError", {
                            msg: {
                                msg: 'Unable request to server, please try again.',
                                title: ' '
                            }
                        })
                    }
                }).catch(() => {
                    // Can not validate transaction ??? 
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: "Cannot validate transaction. Please contact us!",
                            title: 'Transaction Hash: ' + result
                        }
                    })
                })
            }
        })

        */

    },

};

const mutations = {

    UITopupPaymentSuccess(state, {
        msg
    }) {
        state.success = msg;
    },

    UITopupPaymentError(state, {
        msg
    }) {
        state.error = msg;
    },
};

export const minting = {
    namespaced: true,
    state,
    actions,
    mutations
};