<style>
.inline-block {
  display: inline-block;
}

.invoice-wrapper {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 3px;
}

.invoice {
  clear: both;
}

.invoice > p > span {
  float: right;
}

.text-right {
  text-align: right;
}

.btn-checkout {
  margin-left: 20px;
  margin-top: 20px;
  float: right;
}

.txt-quantity {
  width: 80px;
  display: inline;
  margin-left: 20px;
}

.hr-padding {
  padding: 0;
  margin: 10px 0;
}

.text-blue {
  color: blue;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.loading-wert {
  position: absolute;
  width: 100%;
  align-items: center;
  height: 100%;
  background-color: white;
  display: flex;
  text-align: center;
  justify-content: center;
}

.minsize {
  font-size: 11px;
}

#widget_landplot iframe{
    height: 800px !important;
}

</style>
<template>
  <div
    class="modal modal-blur fade show"
    id="toup-weirt3"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    style="display: block"
    v-if="isWertCartNoticeDialogShow"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center">
            Please Have Your Mobile Phone With You For Secure Identification.
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeNoticeDialog"
          ></button>
        </div>
        <div class="modal-body" style="font-size: 11px">
          <p>
            By proceeding with the purchase of GOALAND ("Transaction"), you
            (“User”) understand that your payment and information will be
            processed by Wert (“SHA2 Solutions”). The User will be redirected to
            Wert's payment module during the checkout process and the User must
            read and accept the Terms and Conditions and Privacy Policy prior to
            finalizing their purchase. GOALAND, Gate of Abyss, and The Tipsy
            Company are not responsible for the handling or processing of any
            payment or personal information related to the User as part of the
            Transaction.
          </p>
          <p class="text-center">
            <a
              class="btn btn-xs btn-default bg-blue text-white"
              @click="gotoWertPayment"
              >PROCEED</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal modal-blur fade show"
    id="toup-weirt2"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    style="display: block"
    v-if="isWertDialogShow"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Buy Land Plots via Credit Card</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeWertDialog"
          ></button>
        </div>
        <div class="modal-body" style="padding: 0; min-height: 500px">
          <div v-if="!wertLoaded" class="loading-wert">
            <video
              autoplay
              loop
              mode="normal"
              src="@/assets/loading.mp4"
              style="width: 80px"
            />
          </div>
          <div id="widget_landplot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      creditAmount: 1,
      step: 0,
      total: 0,
      discount: 0,
      discountPercent: 0,
      subTotal: 0,
      missingCount: 0,
      nextDiscount: 0,
      discountPrograms: [],
      perPlot: 0,
      suggessBlock: {
        show: false,
        amount: 0,
        total: 0,
      },
      isLandSaleNoticeShow: false,
    };
  },
  methods: {
    async closeNoticeDialog() {
      this.$store.dispatch("cart/hideWertNotice");
      var result = await this.$swal.fire({
        title: "Do you buy a plot of land with a visa card with Stripe?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Confirm",
        denyButtonText: `Cancel`,
      });
      if (result.isConfirmed) {
        this.$store.dispatch("map/openLoading", { status: true });
        var responseData = await this.$store.dispatch(
          "cart/creditPaymentStripe",
          {
            type: 2,
            collection: [],
            items: [],
          }
        );
        this.$store.dispatch("map/openLoading", { status: false });
        console.log(responseData, "responseData Stripe");
        if (responseData.error) {
          this.$swal(responseData.msg, "Sorry", "error");
        } else {
          document.location.href = responseData.data;
        }
      }
    },
    suggessCredit(quantity) {
      this.creditAmount = quantity;
    },
    async closeWertDialog() {
      this.$store.dispatch("cart/terminateCartWertIOPayment");
      var result = await this.$swal.fire({
        title: "Do you buy a plot of land with a visa card with Stripe?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Confirm",
        denyButtonText: `Cancel`
      });
      if (result.isConfirmed) {
        this.$store.dispatch("map/openLoading", { status: true });
        var responseData = await this.$store.dispatch(
          "cart/creditPaymentStripe",
          {
            type: 2,
            collection: [],
            items: []
          }
        );
        this.$store.dispatch("map/openLoading", { status: false });
        console.log(responseData, "responseData Stript");
        if (responseData.error) {
          this.$swal(responseData.msg, "Sorry", "error");
        } else {
          document.location.href = responseData.data;
        }
      }
    },
    gotoWertPayment() {
      // Pay with creditcard tool
      this.$store.dispatch("cart/wertioPayment", {
        type: 2,
        collection: [],
        items: [],
      });
    },
  },
  computed: {
    ...mapState("cart", [
      "isWertCartNoticeDialogShow",
      "quantity",
      "error",
      "success",
      "wertLoaded",
      "isWertDialogShow",
      "landPlotBuyResult",
      "landPlotBlcProcess",
    ]),
  },
  watch: {
    creditAmount: function () {
      this.getDiscountPrice();
    },
    error: function (value) {
      this.$swal(value.msg, value.title, "error");
    },
    success: function (value) {
      this.$swal(value.msg, value.title, "success");
    },
    showLandSaleDialog: function () {
      this.isLandSaleNoticeShow = true;
    },
    landPlotBuyResult: function ({ error, title, msg }) {
      if (error) {
        this.$swal(msg, title, "error");
      } else {
        this.$swal(msg, title, "success");
      }
    },
    landPlotBlcProcess: function ({ error, title, msg }) {
      if (error) {
        this.$swal(msg, title, "error");
      } else {
        this.$swal(msg, title, "success");
      }
    },
  },
  mounted() {},
};
</script>
