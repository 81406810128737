import Vuex from 'vuex';

import { account } from './modules/account';
import { cart } from './modules/cart';
import { map } from './modules/map';
import { collections } from './modules/collections';
import { landCreditTopup } from './modules/landCreditTopup';
import { minting } from "./modules/minting";
import { web3_login } from "./modules/web3_login";

export const store = new Vuex.Store({
    modules: {
        account,
        cart,
        map,
        collections,
        landCreditTopup,
        minting,
        web3_login
    }
});