import {
    createRouter,
    createWebHistory
} from 'vue-router'

import AboutPage from '../pages/AboutPage.vue'
import MapPage from '../pages/MapPage.vue'
import LoginPage from '../pages/LoginPage.vue'
import RegistryPage from '../pages/RegistryPage.vue'
import EmailActive from '../pages/EmailActive.vue'
import ForgotPasswordSendEmail from '../pages/ForgotPasswordSendEmail.vue'
import ForgotPasswordUpdate from '../pages/ForgotPasswordUpdate.vue'
import ConnectWallet from '../pages/ConnectWallet.vue'
import TermsOfService from '../pages/TermsOfService.vue'
import StripePaymentVue from '../pages/StripePayment.vue'
import MyAssets from '../pages/MyAssets.vue'
import CollectionDetails from '../pages/CollectionDetails.vue'
import ChangePassword from "../pages/ChangePassword.vue";
import AssetsMap from '../pages/AssetsMap.vue'
import CollectionLand from '../pages/CollectionLand.vue'
import ChangeProfile from "../pages/ChangeProfile.vue";
import ConvertGin from "../pages/ConvertGin.vue";
import LinkEmailAccount from '../pages/LinkEmailAccount.vue'
import UpdateUsername from "../pages/UpdateUsername.vue";

const routes = [{
        path: '/',
        component: MapPage
    },
    {
        path: '/about',
        component: AboutPage
    },
    {
        path: '/convert-gin',
        component: ConvertGin
    },

    {
        path: '/login',
        component: LoginPage,
        name: "login"
    },
    {
        path: '/registry',
        component: RegistryPage,
        name: "registry"
    },
    {
        path: '/email_active',
        component: EmailActive,
        name: "email_active"
    },
    {
        path: '/forgot_password_send_email',
        component: ForgotPasswordSendEmail,
        name: "forgot_password_send_email"
    },
    {
        path: '/forgot_password_update',
        component: ForgotPasswordUpdate,
        name: "forgot_password_update"
    },
    {
        path: '/link_email_account',
        component: LinkEmailAccount,
        name: "link_email_account"

    }, {
        path: '/connect_wallet',
        component: ConnectWallet,
        name: "connect_wallet"
    },
    {
        path: '/terms_of_service',
        component: TermsOfService,
        name: "terms_of_service"
    },
    {
        path: '/stripe_payment/success',
        component: StripePaymentVue,
        name: "stripe_payment_success"
    },
    {
        path: '/stripe_payment/cancel',
        component: StripePaymentVue,
        name: "stripe_payment_cancel"
    },
    {
        path: '/myassets',
        component: MyAssets,
        name: "myassets"
    },
    {
        path: '/assets/collections',
        component: CollectionDetails,
        name: "collection_details"
    },
    {
        path: '/change-password',
        component: ChangePassword,
        name: "change-password"
    },
    {
        path: '/change-profile',
        component: ChangeProfile,
        name: "change-profile"
    },
    {
        path: '/update_username',
        component: UpdateUsername,
        name: "update-username"
    },
    {
        path: '/assets',
        component: AssetsMap,
        name: "assets"
    },
    {
        path: '/item/:id',
        component: CollectionLand,
        name: "collection_land"
    },

]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})


router.beforeEach(async (to, from, next) => {

    if (to.path == '/login') {
        next();
        return;
    }

    if (!localStorage.getItem('jwt')) {
        switch (to.name) {
            case 'login' || 'registry' || 'forgot_password_send_email' || 'forgot_password_update' || 'terms_of_service' || 'email_active':
                next({
                    path: '/'
                });
                break;
            default:
                next();
                break;
        }
        return;
    } else {
        next();
    }
});