<style scoped>
    .page {
        display: block;
    }

    .page.page-center {
        background-color: #1e293b;
    }
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4">
            <!--      // close init connect-->
            <div class="text-center mb-4">
                <RouterLink to="/" class="navbar-brand navbar-brand-autodark">
                    <img src="/img/logo.58aa872c.png" width="150" alt="Tabler"/>
                </RouterLink>
            </div>
            <div class="card card-md">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Forgot Password</h2>
                    <form autocomplete="off" novalidate="true" @submit="doSubmit">
                        <div class="mb-3">
                            <label class="form-label">Email</label>
                            <input type="email" v-model="email"
                                   class="form-control"
                                   placeholder="Enter your email"
                                   autocomplete="off"/>
                        </div>

                        <div class="form-footer">
                            <button class="btn btn-primary w-100" type="submit">Send my email</button>
                        </div>
                    </form>
                </div>
                <div class="hr-text">or</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <router-link class="btn w-100" to="/login" tabindex="-1">Login</router-link>
                        </div>
                        <div class="col">
                            <router-link class="btn w-100" to="/registry" tabindex="-1">Sign up</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        components: {},
        name: "forgot_password",
        data() {
            return {
                email: "",
            };
        },
        methods: {
            doSubmit: function (e) {
                e.preventDefault();

                if (!this.email) {
                    this.$swal("Please enter your email!", "", "error");
                    return;
                }

                axios
                    .post(`/api/account/forgot`, {
                        email: this.email,
                    })
                    .then(r => {
                        console.log(r);
                        if (r.data.error) {
                            this.$swal(r.data.msg, "", "error");
                        } else {
                            this.$swal("The system has sent authentication information to your email. Please verify your email", "", "success");
                            this.email = "";
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        },
    };
</script>
