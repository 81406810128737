<template>
    <template v-if="item">
        <template v-if="!item.state">
            <a v-if="item.total > 0 && item.allow_mint" class="btn btn-default btn-sm" @click="doMintNFT(item._id)">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 12l6 -9l6 9l-6 9z"></path>
                    <path d="M6 12l6 -3l6 3l-6 2z"></path>
                </svg>
                Mint NFT
            </a>
        </template>
        <template v-if="item.state == 3">
            <a class="btn btn-warning btn-sm" @click="fetchMintingStatus(item)">
                Verifying
            </a>
        </template>
        <template v-if="item.state == 1">
            <a class="btn btn-danger btn-sm" @click="fetchMintingStatus(item)">
                Minting ...
            </a>
        </template>
        <template v-if="item.state == 2">
            <a class="btn btn-success btn-sm" @click="doViewNFT(item)">
                NFT
            </a>
        </template>
    </template>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    el: "#app-btn",
    components: {},
    name: "btn_minting",
    props: ['itemx'],
    data() {
        return {
            item: undefined,
            fetchID: undefined,
            timer: undefined
        };
    },
    methods: {
        silentFetchMinting: async function () {
            console.log("Fetch status ", this.item.name);
            try {
                let r = await axios.post(`/api/minting/refresh`, {
                    collectionID: this.item._id,
                });
                if (r.data) {
                    let state = r.data.state;
                    this.status = state;
                    console.log(this.status);
                    this.item.state = state;
                }
            } catch (error) {
                console.log(error);
            }
        },

        fetchMintingStatus: function (item) {
            axios
                .post(`/api/minting/request/mint_status`, {
                    collectionID: item._id,
                })
                .then((r) => {
                    if (r.data && r.data.state == 2) {
                        item.state = 2;
                        this.$swal("NFT mint process completed", "", "success");
                    } else {
                        if (r.data.error == true) {
                            item.state = 0;
                            this.$swal(r.data.msg, "", "error");
                        } else {
                            this.$swal
                                .fire({
                                    title: "NFT minting in progress…",
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: "View transaction",
                                    denyButtonText: `Cancel`,
                                })
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        window.open(process.env.VUE_APP_ETHERSCAN + item.txid);
                                    }
                                });
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        doViewNFT: function (item) {
            this.$swal
                .fire({
                    title: "Your Land Collection NFT has been minted.",
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: "View on OpenSea",
                    denyButtonText: `View Transaction`,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        window.open(process.env.VUE_APP_OPENSEA + item.uriid);
                    }
                    if (result.isDenied) {
                        window.open(process.env.VUE_APP_ETHERSCAN + item.txid);
                    }
                });
        },
        doMintNFT: function (collectionID) {
            let self = this;
            if (this.isMinting) {
                this.$swal
                    .fire({
                        title: "The minting process is currently underway, please wait until it completes and try again later.",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "Close",
                        denyButtonText: `Cancel`,
                    })
                return;
            }
            this.$swal
                .fire({
                    title:
                        "By proceeding, you will convert this Land Collection into an NFT on the ETH Network. Please note that this action cannot be undone. Once minted, you cannot add or remove land plots from this NFT land collection until the release of our marketplace.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Proceed",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        //this.item.state = 4;
                        this.$store.dispatch("minting/request", {
                            collectionID,
                            onReject: () => {
                                self.item.state = 0;
                            },
                            callback: function (txid) {
                                self.status = 3;
                                console.log("Minting completed", txid);
                            },
                        });
                    }
                });
        },
    },
    computed: {
        ...mapState("minting", ["status", "isMinting"]),
    },
    watch: {
        status: function (newVal) {
            console.log(newVal);
            if (newVal.length) {
                newVal.forEach(element => {
                    if (element.id == this.fetchID) {
                        this.item.state = element.state;
                    }
                });
            }
        }
    },
    mounted: function () {
        this.item = { ...this.itemx };
        this.fetchID = this.itemx._id;
        //this.status = this.item.state;
    },
};
</script>
