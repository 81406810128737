<style scoped>
.page {
  display: block;
}

.page.page-center {
  background-color: #1e293b;
}
.avatar.avatar-xl {
  border-radius: 50%;
}
.col-auto {
  position: relative;
}
#fileUpload {
  position: absolute;
  top: 0px;
  opacity: 0;
}
</style>
<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <!--      // close init connect-->
      <vue-metamask ref="metamask" :initConnect="false"></vue-metamask>

      <div class="text-center mb-4">
        <router-link to="/" class="navbar-brand navbar-brand-autodark">
          <img src="/img/logo.58aa872c.png" width="150" alt="Tabler" />
        </router-link>
      </div>
      <div class="card card-md">
        <div class="card-body">
          <h2 class="h2 text-center mb-4">Update Profile</h2>
          <form autocomplete="off" novalidate="">
            <div class="mb-3">
              <label class="form-label">Username</label>
              <input
                type="text"
                v-model="username"
                class="form-control"
                placeholder="Username"
                autocomplete="off"
              />
            </div>
            <div class="mb-2">
              <div class="row align-items-center">
                <div class="col-auto">
                  <span
                    class="avatar avatar-xl"
                    :style="'background-image: url(' + avatar + ')'"
                  ></span>
                </div>
                <div class="col-auto">
                  <a href="#" class="btn"> Change avatar </a>
                  <input
                    v-on:change="changeFileUploadImage()"
                    type="file"
                    class="form-control"
                    id="fileUpload"
                    ref="fileUpload"
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
            <div class="form-footer">
              <button
                v-on:click="doSubmit()"
                class="btn btn-primary w-100"
                type="button"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueMetamask from "vue-metamask";
export default {
  components: {
    VueMetamask,
  },
  name: "login-app",
  data() {
    return {
      username: "",
      newPassword: "",
      newPassword2: "",
      avatar: "/img/noavatar.3d3b3f92.svg",
      files: [],
    };
  },
  mounted() {
    let self = this
    axios
      .get("/api/account/profile")
      .then((response) => {
        self.avatar =  response.data.user.avatar
        self.username =  response.data.user.username
      })
      .catch((error) => {
        console.error("Check account", error);
      });
  },
  methods: {
    changeFileUploadImage: function () {
      let self = this;
      var file = document.getElementById("fileUpload").files[0];
      var size_avatar = file.size / 1024 / 1024;
      if (size_avatar > 1) {
        this.$swal("Image size less than 1MB!", "", "error");
        return;
      }
      console.log("This file size is: " + file.size / 1024 / 1024 + "MiB");
      var reader = new FileReader();
      reader.onloadend = function () {
        self.avatar = reader.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    doSubmit() {
      let self = this
      if (!self.username) {
        self.$swal("Please enter username", "", "error");
        return;
      }
      axios
        .post(`/api/account/change_profile`, {
          username: self.username,
          avatar: self.avatar,
        })
        .then((r) => {
          let data = r.data;
          if (!data.error) {
            self.$swal(data.msg, "", "success");
            self.$store.dispatch("account/checkSession");
          } else {
            self.$swal(data.msg, "", "error");
          }
        })
        .catch((e) => { 
          console.log(e);
        });
    },
  },
};
</script>
