<style scoped>
header .text-muted {
  color: #565d6d !important;
}

.navbar .navbar-nav .nav-link {
  color: white;
}

.cart-table {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

#cart .text-truncate {
  max-width: 250px;
}

.tooltip {
  position: relative;
  opacity: 1;
}

.tooltip__item {
  position: absolute;
  min-width: 100px;
  padding: 20px;
  visibility: hidden;
  opacity: 0;
  background: white;
  transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
  color: #484848;
  border: 1px solid #cecece;
  border-radius: 3px;
  font-weight: 500;
  box-shadow: 0 2px 1px #bcbcbc;
  z-index: 4;
}

.tooltip__item:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.tooltip__initiator {
  cursor: pointer;
  z-index: 5;
}

.tooltip[data-direction="left"] .tooltip__initiator:hover ~ .tooltip__item {
  transform: translate3d(0, -50%, 0);
  visibility: visible;
  opacity: 1;
}

.tooltip[data-direction="left"] .tooltip__item {
  top: 50%;
  right: calc(100% + 1em);
  transform: translate3d(15px, -50%, 0);
}

.tooltip[data-direction="left"] .tooltip__item:after {
  top: 50%;
  right: -0.5em;
  transform: translate3d(0, -50%, 0);
  border-width: 0.5em 0 0.5em 0.5em;
  border-color: transparent transparent transparent white;
  -webkit-filter: drop-shadow(0px 2px 1px #bcbcbc);
  filter: drop-shadow(0px 2px 1px #bcbcbc);
}

.tooltip[data-direction="top"] .tooltip__initiator:hover ~ .tooltip__item,
.tooltip[data-direction="bottom"] .tooltip__initiator:hover ~ .tooltip__item {
  transform: translate3d(-50%, 0, 0);
  visibility: visible;
  opacity: 1;
}

.tooltip[data-direction="top"] .tooltip__item,
.tooltip[data-direction="bottom"] .tooltip__item {
  left: 50%;
  transform: translate3d(-50%, -15px, 0);
}

.tooltip[data-direction="top"] .tooltip__item:after,
.tooltip[data-direction="bottom"] .tooltip__item:after {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.tooltip[data-direction="top"] .tooltip__item {
  bottom: calc(100% + 1em);
}

.tooltip[data-direction="top"] .tooltip__item:after {
  bottom: -0.5em;
  border-width: 0.5em 0.5em 0 0.5em;
  border-color: white transparent transparent transparent;
  filter: drop-shadow(0px 2px 1px #bcbcbc);
  -webkit-filter: drop-shadow(0px 2px 1px #bcbcbc);
}

.tooltip[data-direction="bottom"] .tooltip__item {
  top: calc(100% + 1em);
}

.tooltip[data-direction="bottom"] .tooltip__item:after {
  top: -0.5em;
  border-width: 0 0.5em 0.5em 0.5em;
  border-color: transparent transparent white transparent;
  filter: drop-shadow(0px -1px 1px #bcbcbc);
  -webkit-filter: drop-shadow(0px -1px 1px #bcbcbc);
}

/*.tooltip[data-direction="top"] .tooltip__item:after {*/
/*    left: 95%;*/
/*}*/
.tooltip[data-direction="top"] .tooltip__item,
.tooltip[data-direction="bottom"] .tooltip__item {
  min-width: 300px;
}

.tooltip[data-direction="top"] .tooltip__item:after {
  border-color: #1e2a3b transparent transparent transparent;
}

.no-avatar {
  background-image: url(../assets/images/noavatar.svg);
  border-radius: 50%;
}

.btn-remove-all-cart {
  text-decoration: underline;
  color: #cc23cb;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 414px) {
  #cart .text-truncate {
    max-width: 210px;
  }

  .tooltip[data-direction="top"] .tooltip__item,
  .tooltip[data-direction="bottom"] .tooltip__item[data-v-3b1ca699] {
    min-width: 0px;
    width: 250px;
    padding-right: 20px !important;
  }
}

@media (max-width: 412px) {
  #cart .text-truncate {
    max-width: 200px;
  }
}

@media (max-width: 393px) {
  #cart .text-truncate {
    max-width: 185px;
  }
}

@media (max-width: 390px) {
  #cart .text-truncate {
    max-width: 185px;
  }
}

@media (max-width: 375px) {
  #cart .text-truncate {
    max-width: 170px;
  }
}

@media (max-width: 360px) {
  #cart .text-truncate {
    max-width: 160px;
  }
}
.swal2-confirm.swal2-styled{
  width: 100%;
}
</style>
<style>
  .swal2-confirm.your-confirm-button-class{
    background-color: transparent;
    width: 100%;
    border: 1px solid;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
    width: 80%;
    margin: 15px auto 10px auto;
    cursor: pointer;
  }
  .swal2-deny.your-deny-button-class{
    background-color: transparent;
    width: 100%;
    border: 1px solid;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
    width: 80%;
    margin: 15px auto 10px auto;
    cursor: pointer;
  }
</style>
<template>
  <div class="nav-item dropdown">
    <div class="nav-link d-flex lh-1 text-reset p-0 show">
      <a
        class="nav-link px-3"
        data-bs-toggle="dropdown"
        tabindex="-1"
        aria-label="Show notifications"
        @click="topupMenuIsOpen = !topupMenuIsOpen"
        v-click-outside="onCloseLandCreditMenu"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-wallet"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
          ></path>
          <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
        </svg>
        <span class="badge bg-red"></span>
        <template v-if="status.loggedIn">
          {{ creditAmount }}
          <template v-if="creditAmount == 1">credit</template>
          <template v-else>credits</template>
        </template>
        <div
          class="dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
          v-show="topupMenuIsOpen"
          data-bs-popper="static"
        >
          <a @click="showTopupCreditDialog" class="dropdown-item"
            >Buy Land Credits</a
          >
        </div>
      </a>
    </div>
  </div>

  <div class="nav-link d-md-flex">
    <div class="nav-item dropdown d-md-flex me-3" v-click-outside="onCloseCart">
      <a
        href="#"
        class="nav-link px-0 show"
        @click="openCart"
        data-bs-toggle="dropdown"
        tabindex="-1"
        aria-label="Show notifications"
        aria-expanded="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-shopping-cart"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="6" cy="19" r="2"></circle>
          <circle cx="17" cy="19" r="2"></circle>
          <path d="M17 17h-11v-14h-2"></path>
          <path d="M6 5l14 1l-1 7h-13"></path>
        </svg>
        {{ totalItems }}
      </a>
      <div
        class="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card show"
        id="cart"
        v-if="cartIsOpen"
        data-bs-popper="static"
      >
        <div class="card" v-if="totalItems">
          <div class="card-header">
            <div class="card-title">
              Cart ({{ totalItems }})
              <a @click="removeItemAllToCart()" class="btn-remove-all-cart"
                >Remove all items</a
              >
            </div>
            <div class="ms-auto">
              <span class="text-azure" v-if="priceInCart.discountPercent">
                {{ priceInCart.discountPercent }}% off activated!</span
              >
            </div>
          </div>
          <div class="card-body divide-y">
            <div class="cart-table">
              <div class="table-responsive">
                <table class="table table-vcenter card-table">
                  <tbody>
                    <tr v-for="(item, index) in itemsOnCart" v-bind:key="index">
                      <td>
                        <div class="d-block text-muted text-truncate mt-n1">
                          <b>{{ item.geometry.bbox.join(", ") }}</b>
                        </div>
                      </td>
                      <td>${{ priceInCart.basePrice }}</td>
                      <td>
                        <a @click="removeLandToCart(index)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-square-x"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <rect
                              x="4"
                              y="4"
                              width="16"
                              height="16"
                              rx="2"
                            ></rect>
                            <path d="M10 10l4 4m0 -4l-4 4"></path>
                          </svg>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="px-3">
              <div class="d-flex">
                <b>Sub Total</b>
                <div class="ms-auto">${{ priceInCart.totalsub }}</div>
              </div>
              <div class="d-flex">
                <b>Discount</b>
                <div class="ms-auto">-${{ priceInCart.discount }}</div>
              </div>
            </div>

            <div class="px-3">
              <div class="d-flex">
                <b>Total</b>
                <div class="ms-auto text-end">
                  <h3 class="mb-0">${{ priceInCart.priceTotal }}</h3>
                </div>
              </div>
            </div>

            <div class="px-3">
              <div class="d-flex mb-2">
                <b>Land Collection</b>
                <sup class="ms-auto tooltip" data-direction="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-tabler icon-tabler-info-circle tooltip__initiator"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                    <polyline points="11 12 12 12 12 16 13 16"></polyline>
                  </svg>
                  <div class="tooltip__item bg-dark text-white p-2">
                    All land plots purchased in this order can be grouped
                    together as a land collection. Either create a new land
                    collection, or add them into an existing one. Alternatively,
                    you can choose to purchase them as individual plots
                  </div>
                </sup>
              </div>
              <div class="mb-3">
                <div class="d-flex mb-2">
                  <label class="form-check">
                    <input
                      class="form-check-input mt-2"
                      v-model="type"
                      type="radio"
                      name="form-stars"
                      value="individual"
                      checked=""
                    />
                  </label>
                  <span class="ms-auto form-control"
                    >Purchase as individual plots</span
                  >
                </div>

                <div class="d-flex mb-2">
                  <label class="form-check">
                    <input
                      class="form-check-input mt-2"
                      v-model="type"
                      type="radio"
                      name="form-stars"
                      value="collection"
                    />
                  </label>
                  <span v-if="type != 'collection'" class="ms-auto form-control"
                    >Add to land collection</span
                  >

                  <div class="w-100" v-else>
                    <select class="form-select" v-model="collection">
                      <optgroup label="Add to land collection"></optgroup>
                      <option
                        v-for="(clt, index) in collection_ar"
                        v-bind:key="index"
                        :value="clt.id"
                      >
                        {{ clt.name }}
                      </option>
                      <option value="add" class="text-azure">
                        <a href="#">+ Create new land collection</a>
                      </option>
                    </select>

                    <div class="mt-2" v-if="collection == 'add'">
                      <label class="form-label mb-1"
                        >Land Collection Name</label
                      >
                      <input
                        type="text"
                        class="form-control mb-2"
                        v-model="land_collection_name"
                        :class="{
                          'is-valid': land_collection_name,
                          'is-invalid':
                            !land_collection_name && collection_name_error,
                        }"
                        placeholder="Enter land collection name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="px-3">
              <div class="d-flex mb-3">
                <b>Total payment</b>
                <div class="ms-auto text-end">
                  <h3 class="mb-0">${{ priceInCart.priceTotal }}</h3>
                  <!--                                    <span class="text-azure">or 10 Credits</span>-->
                </div>
              </div>

              <div
                class="text-center text-azure mt-3 mb-1"
                v-if="priceInCart.missingCount"
              >
                Select {{ priceInCart.missingCount }} more plots and get
                {{ priceInCart.nextDiscount }}% off
              </div>
              <div>
                <a @click="checkout" class="btn btn-primary active w-100">
                  CHECKOUT
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="card" v-else>
          <div class="card-header">
            <div class="card-title">No Tiles Selected</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-item dropdown">
    <template v-if="!status.loggedIn">
      <div class="nav-item dropdown">
        <div class="btn-list">
          <RouterLink to="/login" class="btn btn-dark" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-login"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"
              ></path>
              <path d="M20 12h-13l3 -3m0 6l-3 -3"></path>
            </svg>
            Login / Sign up
          </RouterLink>
        </div>
      </div>
    </template>
    <template v-else>
      <a
        class="nav-link d-flex lh-1 text-reset p-0 show"
        data-bs-toggle="dropdown"
        aria-label="Open user menu"
        aria-expanded="true"
        @click="menuIsOpen = !menuIsOpen"
        v-click-outside="onCloseUserMenu"
      >
        <span
          :style="'background-image:url(' + user.avatar + ')'"
          class="avatar avatar-sm no-avatar"
        ></span>
        <div class="d-none d-xl-block ps-2">
          <div><b>Hi</b> {{ user.display_name }}</div>
        </div>
        <div
          class="dropdown-menu dropdown-menu-end dropdown-menu-arrow"
          data-bs-popper="static"
          :class="{
            show: menuIsOpen,
          }"
        >
          <a
            v-if="!user.walletAddress"
            @click="connectMetamask"
            class="dropdown-item"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-wallet"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
              ></path>
              <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
            </svg>
            Connect Wallet
          </a>
          <a v-else @click="connectMetamask" class="dropdown-item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-wallet"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path
                d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"
              ></path>
              <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
            </svg>
            {{ cutWalletAddress(user.walletAddress) }}
          </a>
          <RouterLink
            v-if="user && user.wl == 1"
            to="/assets"
            class="dropdown-item"
            >My Assets</RouterLink
          >
          <a v-else class="dropdown-item text-muted" style="opacity: 0.5"
            >My Assets</a
          >
          <RouterLink to="/convert-gin" class="dropdown-item"
            >Convert Gin ($gin)</RouterLink
          >
          <RouterLink to="/change-profile" class="dropdown-item"
            >Update Profile</RouterLink
          >
          <RouterLink to="/change-password" class="dropdown-item"
            >Change Password</RouterLink
          >
          <a @click="doLogout" class="dropdown-item">Logout</a>
        </div>
      </a>
    </template>
  </div>
</template>
<script>
import { RouterLink } from "vue-router";
import { mapState } from "vuex";
import axios from "axios";

export default {
  data() {
    return {
      topupMenuIsOpen: false,
      menuIsOpen: false,
      priceInCart: {
        totalsub: 0,
        discount: 0,
        priceTotal: 0,
        nextDiscount: 0,
        missingCount: 0,
        discountPercent: 0,
        basePrice: 0,
      },
      type: "individual",
      collection_ar: [],
      collection: undefined,
      land_collection_name: "",
      collection_name_error: false,
      API_URL: process.env.VUE_APP_BASE_URL
    };
  },
  components: { RouterLink },
  computed: {
    ...mapState("account", ["status", "user", "creditAmount"]),
    ...mapState("cart", [
      "totalItems",
      "itemsOnCart",
      "totalPrice",
      "cartIsOpen",
    ]),
    ...mapState("web3_login", ["loginRequestStatus"]),
  },
  methods: {
    cutWalletAddress(str) {
      return str.substr(0, 5) + "..." + str.substr(str.length - 5);
    },
    connectMetamask() {
      this.$swal
        .fire({
          title: "Connect Wallet",
          html: 'Connect your wallet to link your account to your wallet address. This enables you to perform blockchain transactions such as managing your NFTs and GIN rewards. <br>',
          showDenyButton: true,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButton: "connect-metamask",
          confirmButtonText: '<a><img width="35" src="'+this.API_URL+'/static/swal/metamaskwallet.png" />Connect MetaMask</a>',
          denyButtonText: `<a><img width="32" style="margin-right: 3px"  src="`+this.API_URL+`/static/swal/safepalwallet.png" />Connect SafePal</a>`,
          customClass: {
            confirmButton: 'your-confirm-button-class',
            cancelButton: 'your-cancel-button-class',
            denyButton: 'your-deny-button-class',
          },
        })
        .then((result) => {
          if (result.isConfirmed || result.isDenied) {
            this.$store.dispatch("web3_login/metamask");
          }
        });
    },
    doLogout() {
      this.$swal
        .fire({
          title: "Are you sure you want to logout?",
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: "Logout",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          result.isConfirmed &&
            this.$store.dispatch("account/logout") &&
            this.$store.dispatch("cart/removeItemAll");
        });
    },

    removeLandToCart(index) {
      this.$store.dispatch("cart/removeItem", {
        index: index,
      });
    },

    removeItemAllToCart() {
      this.$store.dispatch("cart/removeItemAll");
    },

    checkout() {
      let self = this;

      if (this.type === "collection" && this.collection === "add") {
        if (!self.land_collection_name) {
          this.collection_name_error = true;
          this.$swal("Haven't entered a new collection name yet!", "", "error");
          return;
        }

        axios
          .post(`/api/collection/create`, {
            name: self.land_collection_name,
          })
          .then((response) => {
            if (!response.data.error) {
              console.log(response.data);
              self.collection = response.data.inserted._id;
              this.getMyCollection();

              this.$store.dispatch("cart/checkout", {
                type: this.type,
                collection_id: this.collection,
                collection_name: this.land_collection_name,
              });
            } else {
              this.$swal(response.data.msg, "Sorry", "error");
            }
          })
          .catch((e) => {
            console.log(e);
            this.$swal("Please try again, network error", "Sorry", "error");
          });
      } else {
        this.$store.dispatch("cart/checkout", {
          type: this.type,
          collection_id: this.collection,
          collection_name: this.land_collection_name,
        });
      }
    },

    openCart() {
      this.$store.dispatch("cart/openCart", {
        status: true,
      });
    },

    getPriceInCart() {
      let self = this;

      axios
        .post(`/api/getprice`, {
          items: self.itemsOnCart.map((e) => {
            return {
              location: e.geometry.coordinates,
              bbox: e.geometry.bbox,
            };
          }),
        })
        .then((response) => {
          if (response.data && !response.data.error) {
            self.priceInCart = response.data.data;

            self.$store.dispatch("cart/updatePrice", {
              price: self.priceInCart.priceTotal,
              price_items: self.priceInCart,
            });
          } else {
            this.$swal(response.data.msg, "Sorry", "error");
          }
        })
        .catch((e) => {
          console.log(e);
          this.$swal("Please try again, network error", "Sorry", "error");
        });
    },

    showTopupCreditDialog() {
      this.$store.dispatch("landCreditTopup/showModal", {});
    },
    getMyCollection() {
      let self = this;

      axios.get(`/api/collections/items`, {}).then((response) => {
        if (response.data && !response.data.error) {
          self.collection_ar = response.data.items.filter((e) => {
            return !e.state;
          });
          if (!self.collection && self.collection_ar.length > 0) {
            self.collection = self.collection_ar[0].id;
          }
        } else {
          this.$swal(response.data.msg, "Sorry", "error");
        }
      });
    },

    onCloseLandCreditMenu() {
      this.topupMenuIsOpen = false;
    },
    onCloseUserMenu() {
      this.menuIsOpen = false;
    },
    onCloseCart(event) {
      if (!event.target.classList.contains("btn")) {
        this.$store.dispatch("cart/openCart", { status: false });
      }
    },
  },
  async mounted() {
    let self = this;
    let userInfomation = await this.$store.dispatch("account/checkSession");
    this.getMyCollection();
    if (userInfomation) {
      if (!userInfomation.error) {
        if (!userInfomation.user.active) {
          if (
            self.$router.currentRoute.value.fullPath != "/link_email_account"
          ) {
            this.$swal
              .fire({
                title:
                  "Please link your account with an email address. After inserting your email, you will need to verify it through the link you receive in your inbox.",
                showCancelButton: true,
                confirmButtonText: "Link email",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  self.$router.push("/link_email_account");
                }
              });
          }
        }
      }
    }
  },

  updated() {},

  watch: {
    totalItems: function () {
      let data = {
        items: this.itemsOnCart,
        count: this.totalItems,
        price: this.totalPrice,
      };
      console.log("data", data);
      this.getPriceInCart();
    },
    type: function () {
      if (this.type == "collection") {
        // this.getMyCollection();
      }
    },
    loginRequestStatus: function () {
      let e = this.loginRequestStatus;
      if (!e.error) {
        this.$store.dispatch("account/checkSession");
      } else {
        this.$swal(e.msg, "", e.error ? "error" : "success");
      }
    },
  },
};
</script>
