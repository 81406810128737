<style>
.inline-block {
    display: inline-block;
}

.invoice-wrapper {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 3px;
}

.invoice {
    clear: both;
}

.invoice>p>span {
    float: right;
}

.text-right {
    text-align: right;
}

.btn-checkout {
    margin-left: 20px;
    margin-top: 20px;
    float: right;
}

.txt-quantity {
    width: 80px;
    display: inline;
    margin-left: 20px;
}

.hr-padding {
    padding: 0;
    margin: 10px 0;
}

.text-blue {
    color: blue;
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.loading-wert {
    position: absolute;
    width: 100%;
    align-items: center;
    height: 100%;
    background-color: white;
    display: flex;
    text-align: center;
    justify-content: center;
}

.minsize {
    font-size: 11px;
}
#widget iframe{
    height: 800px !important;
}
</style>
<template>


    <div class="modal modal-blur fade show" id="toup-weirt" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" v-if="isLandSaleNoticeShow">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class=" modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center">Please sign up for an account or login to add land plots to cart.</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="isLandSaleNoticeShow = false"></button>
                </div>
                <div class="modal-body">
                    <p>
                        The game map of the Gate of Abyss world is a 1:1 scale of the physical world. You can own plots of land, which entitles you to earn real world income based on transactions that happen on your land plots, amongst other perks. You can buy land plots using credit/debit card, Ethereum (on Mainnet), or land credits. Enjoy privileged pricing if you buy in bulk, and during this early bird pricing special!
                    </p>
                    <p class="text-center">
                        <a class="btn btn-xs btn-default bg-blue text-white" @click="buyLandCreditCTA">Login / Sign up</a>
                    </p>
                </div>
            </div>
        </div>
    </div>


    <div class="modal modal-blur fade show" id="toup-weirt" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" v-if="isNoticeDialogShow">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class=" modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-center">Please Have Your Mobile Phone With You For Secure Identification.</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeNoticeDialog"></button>
                </div>
                <div class="modal-body" style="font-size:11px">
                    <p>
                        By proceeding with the purchase of GOALAND ("Transaction"), you (“User”) understand that your payment and information will be processed by Wert (“SHA2 Solutions”). The User will be redirected to Wert's payment module during the checkout process and the User must read and accept the Terms and Conditions and Privacy Policy prior to finalizing their purchase. GOALAND, Gate of Abyss, and The Tipsy Company are not responsible for the handling or processing of any payment or personal information related to the User as part of the Transaction.
                    </p>
                    <p class="text-center">
                        <a class="btn btn-xs btn-default bg-blue text-white" @click="gotoWertPayment">PROCEED</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-blur fade show" id="toup-weirt" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" v-if="isWertDialogShow">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class=" modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Buy Land Credits via Credit Card</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeWertDialog"></button>
                </div>
                <div class="modal-body" style="padding:0;min-height: 500px;">
                    <div v-if="!wertLoaded" class="loading-wert"><video autoplay loop mode="normal" src="@/assets/loading.mp4" style="width: 80px" /></div>
                    <div id="widget"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-blur fade show" id="toup-payment-method" tabindex="-1" role="dialog" aria-modal="true" style="display: block;" v-if="isShowModal">
        <div class="modal-dialog modal-dialog-centered  modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Buy Land Credits</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <p>Each land credit can redeem one land plot in any part of the world.</p>
                    <div class="row">
                        <div class="col-sm-12 col-md-5" v-if="(step == 0)">
                            <p>
                                <b>Add Credit</b>
                                <input type="number" min="0" step="1" class="form-control txt-quantity" placeholder="10" v-model="creditAmount" />
                            </p>
                            <p v-for="item in discountPrograms" :key="item._id">
                                <a @click="suggessCredit(item.amount)" class="btn btn-primary w-100" :class="{ 'bg-red': (suggessBlock.amount == item.amount) }">
                                    {{ item.amount }}
                                    <template v-if="item.amount == 1">
                                        Credit
                                    </template>
                                    <template v-else>
                                        Credits
                                    </template>
                                    ({{ item.discount }}% OFF)
                                </a>
                            </p>
                            <p v-show="suggessBlock.show" class="text-red">
                                Please consider getting {{ suggessBlock.amount }} credits for {{ currencyFormat(suggessBlock.total) }}.
                            </p>
                        </div>
                        <div class="col-sm-12 col-md-5" v-if="(step == 1)">
                            <a @click="payWithCreditCard()" class="btn btn-primary active w-100 mb-2 py-3">
                                <div class="w-100">
                                    <div class="float-start">CREDIT / DEBIT CARD</div>
                                    <div class="float-end"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card me-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" d>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <rect x="3" y="5" width="18" height="14" rx="3"></rect>
                                            <line x1="3" y1="10" x2="21" y2="10"></line>
                                            <line x1="7" y1="15" x2="7.01" y2="15"></line>
                                            <line x1="11" y1="15" x2="13" y2="15"></line>
                                        </svg></div>
                                </div>
                            </a>
                            <p class="minsize">Payment processed by Wert. Final sale prices will be based on Wert’s real-time USD/ETH exchange rate. A 4% transaction fee will be applied towards your purchase.</p>
                            <a @click="(step = 2)" class="btn btn-primary active w-100 mb-2 py-3">
                                <div class="w-100">
                                    <div class="float-start">CRYPTOCURRENCY</div>
                                    <div class="float-end">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-bitcoin me-0" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8"></path>
                                            <line x1="8" y1="6" x2="8" y2="18"></line>
                                            <line x1="8" y1="12" x2="14" y2="12"></line>
                                            <line x1="9" y1="3" x2="9" y2="6"></line>
                                            <line x1="13" y1="3" x2="13" y2="6"></line>
                                            <line x1="9" y1="18" x2="9" y2="21"></line>
                                            <line x1="13" y1="18" x2="13" y2="21"></line>
                                        </svg>

                                    </div>
                                </div>
                            </a>
                            <p class="minsize">Pay directly in crypto from your wallet. Pay only gas fees, no transaction fee required.</p>
                            <a @click="(step = 0)" class="btn btn-default w-100 ">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M14 6l-6 6l6 6v-12"></path>
                                </svg> Back</a>
                        </div>

                        <div class="col-sm-12  col-md-5" v-if="(step == 2)">
                            <p style="font-size: 11px;margin-bottom: 5px;">Supported Wallet : Metamask</p>
                            <a @click="payWithCrypto('eth')" class="btn btn-primary active w-100 mb-2 py-3">
                                <div class="w-100">
                                    <div class="float-start">ETH</div>
                                    <div class="float-end">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                            <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                        </svg>
                                    </div>
                                </div>
                            </a>
                            <a @click="(step = 1)" class="btn btn-default w-100 ">
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M14 6l-6 6l6 6v-12"></path>
                                </svg> Back</a>
                        </div>

                        <div class="col-sm-12  col-md-7">
                            <div class="invoice-wrapper">
                                <p v-if="discountPercent" class="text-right text-blue">{{ discountPercent }}% off activated!</p>

                                <div class="invoice">
                                    <p>Total credits <span>{{ creditAmount }}</span></p>
                                </div>
                                <hr class="hr-padding" />
                                <div class="invoice">
                                    <p class="mb-1"><b>Sub Total</b><span>{{ currencyFormat(subTotal) }}</span></p>
                                </div>
                                <div class="invoice text-red">
                                    <p><b>You Save</b> <span>-{{ currencyFormat(discount) }}</span></p>
                                </div>
                                <div class="invoice" style="font-size: 25px;">
                                    <p class="m-0">
                                        <b>Total</b> <span>
                                            <b>{{ currencyFormat(total) }}</b>
                                        </span><br />
                                    </p>
                                </div>
                                <div class="invoice">
                                    <p>
                                        <b>&nbsp;</b>
                                        <span>
                                            ({{ currencyFormat(perPlot) }} per plot)</span>
                                    </p>
                                </div>

                                <hr class="hr-padding" />

                                <div class="text-right text-blue" v-if="missingCount">
                                    <b>Select {{ missingCount }} more plots and get {{ nextDiscount }}% off.</b>
                                </div>
                            </div>

                            <a v-if="(step == 0)" @click="checkout()" class="btn btn-primary   btn-checkout">
                                CHECKOUT
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
    components: {
    },
    data() {
        return {
            creditAmount: 1,
            step: 0,
            total: 0,
            discount: 0,
            discountPercent: 0,
            subTotal: 0,
            missingCount: 0,
            nextDiscount: 0,
            discountPrograms: [],
            perPlot: 0,
            suggessBlock: {
                show: false,
                amount: 0,
                total: 0
            },
            isLandSaleNoticeShow: false
        }
    },
    methods: {
        currencyFormat(v) {
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
            });
            return formatter.format(v > 0 ? v : 0)
        },
        closeModal() {
            this.$store.dispatch("landCreditTopup/terminateLandCreditTopup");
        },
        async closeNoticeDialog() {
            this.$store.dispatch("landCreditTopup/terminalNoticeDialog");
            var result = await this.$swal.fire({
                title: "Payment unsuccessful. Please try again.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Cancel`
            });
            if (result.isConfirmed) {
                this.$store.dispatch("map/openLoading", { status: true });
                var responseData = await this.$store.dispatch("landCreditTopup/creditPaymentStripe", {
                    amount: this.creditAmount
                });
                this.$store.dispatch("map/openLoading", { status: false });
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
        },
        suggessCredit(quantity) {
            this.creditAmount = quantity;
        },
        checkout() {
            // Goto payment state           
            //
            if (this.creditAmount <= 0) {
                return
            }
            // Check is logined, if not redirect to login page !!!
            if (this.status && this.status.loggedIn == true) {
                this.step = 1
                // Fire facebook event checkout button click
                /*eslint-disable */
                fbq('track', 'InitiateCheckout');
                /*eslint-enable */
            } else {
                this.$store.dispatch("landCreditTopup/terminateLandCreditTopup");
                this.$swal({
                    title: ' Please Login To Your Account To Buy Land Credits ',
                    confirmButtonText: 'LOGIN / SIGN UP',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$router.replace('/login');
                    }
                });
            }
        },
        payWithCreditCard() {
            this.$store.dispatch("landCreditTopup/showWertNotice");
            /*
            // Pay with creditcard tool
            this.$store.dispatch("landCreditTopup/wertioPayment", {
                amount: this.creditAmount
            });*/
        },
        payWithCrypto(currency) {
            // Start payment with crypto
            if (currency == 'eth') {
                this.$store.dispatch("landCreditTopup/ethCryptoPayment", {
                    amount: this.creditAmount
                });
            }
        },
        async closeWertDialog() {
            this.$store.dispatch("landCreditTopup/terminateWertIOPayment");
            var result = await this.$swal.fire({
                title: "Payment unsuccessful. Please try again.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Cancel`,
            });
            if (result.isConfirmed) {
                this.$store.dispatch("map/openLoading", { status: true });
                var responseData = await this.$store.dispatch("landCreditTopup/creditPaymentStripe", {
                    amount: this.creditAmount
                });
                this.$store.dispatch("map/openLoading", { status: false });
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
        },
        // Fetch all discount program
        getDiscountPrograms() {
            axios.get('/api/credit/discount_program')
                .then(r => {
                    if (r.data && !r.data.error) {
                        this.discountPrograms = r.data.items;
                    }
                }).catch({});
        },
        getDiscountPrice() {
            // When change credit amount, re calculate pricing suggesion
            let amount = parseInt(this.creditAmount) ? parseInt(this.creditAmount) : 0
            axios
                .get(`/api/credit/price/${amount}`)
                .then(r => {
                    let data = r.data;
                    this.subTotal = data.subTotal
                    this.discount = data.discount
                    this.total = data.total
                    this.missingCount = data.missingCount
                    this.nextDiscount = data.nextDiscount
                    this.discountPercent = data.discountPercent
                    this.perPlot = data.total / amount
                    let nextFee = (data.missingCount + amount) * data.basePrice - data.nextDiscount * (data.missingCount + amount) * data.basePrice / 100
                    if (data.total > nextFee) {
                        // Show artisan suggesion you can buy more
                        this.suggessBlock = {
                            show: true,
                            amount: data.missingCount + amount,
                            total: nextFee
                        }
                    } else {
                        this.suggessBlock = {
                            show: false,
                            amount: 0,
                            total: 0
                        }
                    }
                }).catch(() => {

                });
        },
        gotoWertPayment() {
            // Pay with creditcard tool
            this.$store.dispatch("landCreditTopup/wertioPayment", {
                amount: this.creditAmount
            });
        },
        buyLandCreditCTA() {
            this.isLandSaleNoticeShow = false

            this.$router.push('/login')
            // Show land sale button
            //this.$store.dispatch("landCreditTopup/showModal", {});
        }
    },
    computed: {
        ...mapState("landCreditTopup", ["isShowModal", "isWertDialogShow", "quantity", "error", "success", "wertLoaded", "showLandSaleDialog", "isNoticeDialogShow","showPaymentStripeTopup"]),
        ...mapState("account", ["status"])
    },
    watch: {
        isShowModal: function () {
            this.step = 0
            this.getDiscountPrograms()
            this.getDiscountPrice()
        },
        creditAmount: function () {
            this.getDiscountPrice()
        },
        error: function (value) {
            this.$swal(value.msg, value.title, "error")
            //alert("AAAAAAAAAAAAAAAAAAAAA")
        },
        success: function (value) {
            this.$swal(value.msg, value.title, "success")
        },
        showLandSaleDialog: function () {
            this.isLandSaleNoticeShow = true
        },
         showPaymentStripeTopup:  async function () {
            var result = await this.$swal.fire({
                title: "Payment unsuccessful. Please try again.",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Ok",
                denyButtonText: `Cancel`,
            });
            if (result.isConfirmed) {
                this.$store.dispatch("map/openLoading", { status: true });
                var responseData = await this.$store.dispatch("landCreditTopup/creditPaymentStripe", {
                    amount: this.creditAmount
                });
                this.$store.dispatch("map/openLoading", { status: false });
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
        }
    },
    mounted() {
    },
};
</script>
