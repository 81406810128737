<style scoped>
.card {
    overflow: hidden;
}

.icon-status>svg {
    width: 120px;
    height: 120px;
}

.mw-250 {
    max-width: 250px;
}
</style>
<template>
    <div class="page-wrapper">
        <div class="page-body">
            <div class="container-xl">
                <div class="row g-2 align-items-center">
                    <div class="col">
                        <h2 class="page-title">
                            My assets
                        </h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <ul class="nav nav-bordered mb-4">
                            <li class="nav-item">
                                <a class="nav-link px-3" :class="{ 'active': data_tab == 'land_plots' }" aria-current="page" href="#" @click="data_tab = 'land_plots'">Land Plots
                                    (Individual)</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link px-3" :class="{ 'active': data_tab == 'land_collections' }" href="#" @click="data_tab = 'land_collections'">Land Collections</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="row" v-if="data_tab == 'land_plots'">
                    <assetsLandPlots></assetsLandPlots>

                </div>

                <div class="row" v-if="data_tab == 'land_collections'">
                    <div class="col-lg-12" v-if="data_collections.length">
                        <div class="card">
                            <div class="table-responsive">
                                <table class="table table-vcenter card-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Collections</th>
                                            <th>Total Plots</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in data_collections" v-bind:key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                <a href="#" @click="onDetailColletions(item)">{{ item.name }}</a>
                                            </td>
                                            <td>{{ item.total_plots ? item.total_plots : 0 }}</td>
                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12" v-else>
                    <span class="text-muted">You don't have any collections yet.</span>
                </div>
            </div>

            <!--<div class="row row-cards" v-if="data.length">
                    <div class="col-sm-6 col-lg-3" v-for="(item, index) in data" v-bind:key="index">
                        <div class="card card-sm">
                            <div class="d-block bg-land">
                                <img src="../../static/images/land.png"
                                     class="card-img-top">
                            </div>
                            <div class="card-body">
                                <div class="text-truncate">
                                    {{ item.location.bbox.join(', ') }}
                                </div>
                                <div class="mt-1">
                                    <h3>${{ item.price ? item.price : 0 }}</h3>
                                </div>
                                <div class="text-end mt-3">
                                    <a @click="doMintLand(item)" class="btn btn-dark" data-bs-toggle="modal" data-bs-target="#modal-mint">
                                        Mint
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row row-cards" v-else>
                        <div class="col-lg-12">
                            <span class="text-muted">You don't own any land yet.</span>
                        </div>
                    </div>-->

            </div>
        </div>
    </div>

    <div class="modal modal-blur fade show" id="modal-mint" style="display: block;" tabindex="-1" aria-modal="true" role="dialog" v-if="modal_mint">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Mint success</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="modal_mint = !modal_mint"></button>
                </div>
                <div class="modal-body text-center">
                    <div class="mb-3 icon-status">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-circle-check text-green" width="124" height="124" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="9"></circle>
                            <path d="M9 12l2 2l4 -4"></path>
                        </svg>
                    </div>

                    <div class="">
                        <table class="table table-transparent table-responsive">
                            <tbody>
                                <tr>
                                    <td class="strong text-start">Network</td>
                                    <td class="text-end">
                                        <a :href="ExplorerURL" target="_blank">
                                            {{ NETWORK }}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="strong text-start">Contract Address</td>
                                    <td class="text-end">
                                        <a :href="ExplorerURL + '/address/' + contractAddress" target="_blank">
                                            {{ contractAddress }}
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="strong text-start">Token ID</td>
                                    <td class="text-end">
                                        {{ contractResult.events.Transfer.returnValues.tokenId }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="strong text-start">Transaction Hash</td>
                                    <td class="text-end">
                                        <a :href="ExplorerURL + '/tx/' + contractResult.transactionHash" target="_blank">
                                            {{ contractResult.transactionHash }}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Web3 from "web3";

import assetsLandPlots from "./../components/assetsLandPlots.vue";

export default {
    el: "#app",
    components: {
        assetsLandPlots
    },
    name: "my_assets",

    data() {
        return {
            data_tab: 'land_plots',
            data: [],
            data_collections: [],
            data_table: [],
            modal_mint: false,
            ntf_data: [],
            contractAddress: process.env.VUE_APP_CONTRACT_ADDRESS,
            targetNetworkId: process.env.VUE_APP_NETWORK_ID,
            ExplorerURL: process.env.VUE_APP_EXPLORER_URL,
            NETWORK: process.env.VUE_APP_NETWORK,

            connected: false,
            contractResult: [],
            networkId: "",
            address: "",
            balance: "",

        };
    },
    methods: {



        fetchLands: function () {
            axios
                .get(`/api/lands/my_assets`, {
                    params: {
                        type: 'individual',
                        // mint_status: true
                    }
                })
                .then(r => {
                    let data = r.data;
                    console.log(data);

                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.data = r.data.data;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },

        fetchCollections: function () {
            axios
                .get(`/api/collections/fetch`, {})
                .then(r => {
                    let data = r.data;
                    console.log(data);

                    if (r.data.error) {
                        this.$swal(r.data.msg);
                    } else {
                        this.data_collections = r.data.data;
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },


        doUpdateMintStatus(item) {

            axios
                .post(`/api/mint/status`, {
                    tokenId: parseInt(item.events.Transfer.returnValues.tokenId),
                    transaction_hash: item.transactionHash,
                    from: item.from,
                    to: item.to,
                    status: item.status
                })
                .then(response => {
                    if (response.data && !response.data.error) {
                        console.log(response.data)
                        // this.modal_mint = true;
                        this.fetchLands();
                    } else {
                        this.$swal(response.data.msg, "Sorry", "error");
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$swal("Please try again, network error", "Sorry", "error");
                });
        },

        async connect() {
            const targetNetworkId = process.env.VUE_APP_NETWORK_ID;
            if (typeof window.ethereum !== "undefined") {
                try {
                    try {
                        await window.ethereum.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: targetNetworkId }],
                        });
                    } catch (ex) {
                        console.log(ex);
                    }
                    // Ask to connect
                    await window.ethereum.send("eth_requestAccounts");
                    const instance = new Web3(window.ethereum);
                    // Get necessary info on your node
                    var networkId = await instance.eth.net.getId();
                    var coinbase = await instance.eth.getCoinbase();
                    var balance = await instance.eth.getBalance(coinbase);
                    this.networkId = networkId;
                    this.address = coinbase;
                    this.balance = balance;

                    console.log("initWeb3 networkId: ", networkId);
                    console.log("coinbase networkId: ", coinbase);
                    console.log("balance networkId: ", balance);

                    this.connected = true;

                    // this.$router.go(-1);
                } catch (error) {
                    // User denied account access
                    console.error("User denied web3 access", error);
                    return;
                }
            }
            // No web3 provider
            else {
                console.error("No web3 provider detected");
                return;
            }
        },



        onDetailColletions: function (item) {
            this.$store.dispatch("collections/selectCollection", {
                collection: item
            });
            this.$router.push(`/assets/collections?key=` + item._id);
        },

    },
    mounted: function () {
        this.fetchLands();
        this.fetchCollections();
        this.connect();
    },

    watch: {
        "$route.query.redirected": function () {
            if (this.$route.query.redirected === 'detail') {
                this.fetchCollections();
                this.fetchLands();
            }
        },
    },
};
</script>
