<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4">
            <div class="text-center mb-4">
                <RouterLink to="/" class="navbar-brand navbar-brand-autodark">
                    <img src="/img/logo.58aa872c.png" width="150" alt="Tabler" />
                </RouterLink>
            </div>

            <form class="card card-md" autocomplete="off" novalidate="true" @submit="doSubmit">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Forgot Password</h2>
                    <div class="mb-3">
                        <label class="form-label">New Password</label>
                        <div class="input-group input-group-flat">
                            <input :type="showPassword ? 'text' : 'password'" v-model="data.password" class="form-control" placeholder="Enter password" autocomplete="off" />
                            <span class="input-group-text">
                                <a @click="toggleShow('password')" class="link-secondary" data-bs-toggle="tooltip" aria-label="Show password" data-bs-original-title="Show password"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" v-if="showPassword" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" v-else viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label">Confirm Password</label>
                        <div class="input-group input-group-flat">
                            <input :type="showConfirmPassword ? 'text' : 'password'" v-model="data.confirm_password" class="form-control" placeholder="Enter replay password" autocomplete="off" />
                            <span class="input-group-text">
                                <a @click="toggleShow('confirm_password')" class="link-secondary" data-bs-toggle="tooltip" aria-label="Show password" data-bs-original-title="Show password"><!-- Download SVG icon from http://tabler-icons.io/i/eye -->
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="currentColor" v-if="showConfirmPassword" class="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                                        <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" v-else viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="form-footer">
                        <button type="submit" class="btn btn-primary w-100">Update password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    components: {},
    name: "registry-app",
    data() {
        return {
            data: {
                password: "",
                confirm_password: "",
            },
            showPassword: false,
            showConfirmPassword: false,
        };
    },
    methods: {
        toggleShow(type) {
            switch (type) {
                case "password":
                    this.showPassword = !this.showPassword;
                    break;
                case "confirm_password":
                    this.showConfirmPassword = !this.showConfirmPassword;
                    break;
            }
        },
        verifyKey: function(){
            var key = this.$route.query.key;
            axios.get('/api/account/verify_secret_key/' + key)
            .then(r => {
                if(r.data.error){
                    this.$swal.fire({
                        title: r.data.msg,
                        icon: 'error',
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    });
                }
                
            });
        },
        doSubmit: function (e) {
            e.preventDefault();

            var key = this.$route.query.key;

            if (!key) {
                this.$swal("Secret key error!", "", "error");
                return;
            }

            if (this.data.password != this.data.confirm_password) {
                this.$swal("Your password does not match.", "", "error");
                return;
            }

            axios
                .post(`/api/account/reset_password`, {
                    password: this.data.password,
                    secret_key: key,
                })
                .then(r => {
                    console.log('QUANGr', r);
                    if (r.data.error) {
                        console.log('ERROR');
                        this.$swal(r.data.msg, "", "error");
                    } else {
                        console.log('SUCCESS');
                        this.$swal(r.data.msg, "", "success");
                        this.data = {
                            password: "",
                            confirm_password: "",
                        };
                        this.$router.push("/login");
                    }
                })
                .catch(e => {
                    console.log(e);
                });
        },
    },
    mounted() {
    let self = this;
    self.verifyKey()
  },

};
</script>
