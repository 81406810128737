<style scoped>
ul > li .dropdown-item.text-muted {
  color: inherit;
}

header {
  background: #1e232c;
  color: #fff;
}

#navbar-menu input.form-control {
  border: 1px solid rgba(98, 105, 118, 0.24);
}

.popup-loading {
  background: #ffffffc7;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  line-height: 50;
}

.popup-loading img {
  width: 180px;
}
</style>
<template>
  <div class="page">
    <!-- Navbar -->

    <template
      v-if="
        ![
          'registry',
          'login',
          'email_active',
          'forgot_password_send_email',
          'forgot_password_update',
          'terms_of_service',
        ].includes(this.$route.name)
      "
    >
      <header class="navbar navbar-expand-md navbar-light d-print-none">
        <div class="container-xl">
          <h1
            class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"
          >
            <RouterLink to="/">
              <img
                src="./assets/logo.png"
                width="110"
                height="32"
                alt="Tabler"
                class="navbar-brand-image"
              />
            </RouterLink>
          </h1>
          <div class="navbar-nav flex-row order-md-last">
            <!-- <span v-if="user" class="badge bg-yellow-lt">{{
              user.active
            }}</span> -->
            <AccountModule></AccountModule>
          </div>
        </div>
      </header>
    </template>
    <div class="page-wrapper">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>

    <LandCreditTopup></LandCreditTopup>
    <LandPlotBuy></LandPlotBuy>
  </div>

  <div class="se-pre-con popup-loading text-center" v-if="openLoading">
    <img src="../static/loadding/processing.gif" alt="”animated”" />
  </div>
</template>

<script>
import { RouterLink } from "vue-router";
import AccountModule from "./components/accountModule.vue";
import { mapState } from "vuex";
import LandCreditTopup from "./modals/landCreditTopup.vue";
import LandPlotBuy from "./modals/landPlotBuy.vue";

import axios from "axios";

export default {
  data() {
    return {};
  },
  components: {
    RouterLink,
    AccountModule,
    LandCreditTopup,
    LandPlotBuy,
  },
  computed: {
    ...mapState("map", ["openLoading"]),
    ...mapState("account", ["status", "user", "creditAmount"]),
  },
  methods: {},
  mounted() {
    this.$store.dispatch("account/checkSession");
    axios.get("/api/site/status").then((r) => {
      if (r.data && !r.data.error && r.data.maintain == true) {
        this.$swal.fire({
          title:
            "Our website is undergoing some maintenance. We'll be back soon.",
          icon: "warning",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
      }
    });
  },
};
</script>
