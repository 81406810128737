//import { userService } from '../../services';
//import { router } from "../../helpers/router";

const state = {
    status: {
        loggedIn: false
    },
    user: null,
    creditAmount: 0
}
import axios from "axios";
const actions = {
    // Action assign authenticate user
    assign({
        commit,
        dispatch
    }, {
        user,
        access_token,
        refresh_token
    }) {
        localStorage.setItem("user", JSON.stringify(user));
        console.log(user)
        localStorage.setItem("jwt", access_token);
        localStorage.setItem("jwt_refresh", refresh_token);
        commit("loginSuccess", user);
        dispatch('getCredits');
    },

    getCredits({
        commit
    }) {
        axios.get("/api/credit/total").then(r => {
            if (r.data && !r.data.error) {
                commit("updateCredit", r.data.amount)
            } else {
                // Could not load ~~~ ?
                commit("updateCredit", "loading")
            }
        });
    },

    /**
     * Check sesion 
     * If sucess jwt token logined = as true
     * @param {*} param0
     * @returns 
     */
    async checkSession({
        commit,
        dispatch
    }) {
        let access_token = localStorage.getItem("jwt")
        if (!access_token) {
            console.error("Access token empty");
            return;
        }
        // Make request to backend for get information of user with access token
        try {
            var response = await axios
                .get("/api/account/profile")
            let userInfomation = response.data.user
            commit("loginSuccess", userInfomation);
            dispatch('getCredits');
            return {
                error: false,
                user: userInfomation
            }
        } catch (error) {
            console.error("Check account", error);
            dispatch('logout');
            return {
                error: true
            }
        }
        /*
        let userSerializeData = localStorage.getItem("user");
        if (!userSerializeData || !access_token) {
            return
        }
        try {
            // Parse user login information from cache
            let user = JSON.parse(userSerializeData);
            commit("loginSuccess", user);
            dispatch('getCredits');
        } catch (error) {
            console.log("Can not parse remember user", error);
        }*/
    },

    logout({
        commit
    }) {
        localStorage.setItem("jwt", "")
        localStorage.setItem("jwt_refresh", "")
        localStorage.setItem("user", "")
        commit("logout");
    }

    /*
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);

        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    router.push('/login');
                    setTimeout(() => {
                        // hiển thị message thành công sau redirect sang trang 
                        dispatch('alert/success', 'Registration successful', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }*/
};

const mutations = {
    /*
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },*/
    loginSuccess(state, user) {
        state.status = {
            loggedIn: true
        };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },

    updateCredit(state, amount) {
        state.creditAmount = amount;
    }
    /*
    registerRequest(state, user) {
        console.log(user)
        state.status = { registering: true };
    },
    registerSuccess(state, user) {
        console.log(user)
        state.status = {};
    },
    registerFailure(state, error) {
        console.log(error)
        state.status = {};
    }*/
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
};