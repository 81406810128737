<style scoped>
.page {
  display: block;
}

.page.page-center {
  background-color: #1e293b;
}
</style>
<template>
  <div class="page page-center">
    <div class="container container-tight py-4">
      <!--      // close init connect-->
      <vue-metamask ref="metamask" :initConnect="false"></vue-metamask>

      <div class="text-center mb-4">
        <router-link to="/" class="navbar-brand navbar-brand-autodark">
          <img src="/img/logo.58aa872c.png" width="150" alt="Tabler" />
        </router-link>
      </div>
      <div class="card card-md">
        <div class="card-body">
          <h2 class="h2 text-center mb-4">Log in to your account</h2>
          <form autocomplete="off" novalidate="" @submit="doSubmit">
            <div class="mb-3">
              <label class="form-label">Email address or username</label>
              <input
                type="email"
                v-model="email"
                class="form-control"
                placeholder="username"
                autocomplete="off"
              />
            </div>
            <div class="mb-2">
              <label class="form-label">
                Password
                <span class="form-label-description">
                  <router-link to="/forgot_password_send_email" tabindex="-1"
                    >Forgot password</router-link
                  >
                </span>
              </label>
              <div class="input-group input-group-flat">
                <input
                  type="password"
                  v-model="password"
                  class="form-control"
                  placeholder="Your password"
                  autocomplete="off"
                />
                <span class="input-group-text">
                  <a
                    href="#"
                    class="link-secondary"
                    data-bs-toggle="tooltip"
                    aria-label="Show password"
                    data-bs-original-title="Show password"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <circle cx="12" cy="12" r="2"></circle>
                      <path
                        d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
            <div class="mb-2">
              <label class="form-check">
                <input type="checkbox" class="form-check-input" />
                <span class="form-check-label">Remember me on this device</span>
              </label>
            </div>
            <div class="form-footer">
              <button class="btn btn-primary w-100" type="submit">
                Sign in
              </button>
            </div>
          </form>
        </div>
        <!--
        <div class="hr-text">or</div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <a @click="metamaskLogin" class="btn w-100">
                Login with Metamask
              </a>
            </div>
            <div class="col">
              <a href="#" class="btn w-100"> Login with Twitter </a>
            </div>
          </div>
        </div>
        -->
      </div>
      <div class="text-center text-white mt-3">
        Don't have an account?
        <router-link to="/registry" style="color: #4f9fff" tabindex="-1"
          >Sign up</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import VueMetamask from "vue-metamask";
export default {
  components: {
    VueMetamask,
  },
  name: "login-app",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    metamaskLogin() {
      this.$refs.metamask.init();
    },
    doSubmit: function (e) {
      e.preventDefault();

      axios
        .post(`/api/account/login`, {
          email: this.email,
          password: this.password,
        })
        .then((r) => {
          let data = r.data;
          if (data.error) {
            this.$swal(data.msg);
          } else {
            this.$store.dispatch("account/assign", {
              access_token: data.access_token,
              refresh_token: data.refresh_token,
              user: data.user,
            });
            this.$store.dispatch("cart/removeItemAll");
            this.$router.push("/");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
